<template>
    <app-navigation class="mb-4"
                    :heading="submission ? `Submission: ${submission.title}`:'' ">
        <v-container v-if="!notFound">
            <v-card v-if="submission">
                <v-card-title class="justify-space-between flex-nowrap">
                    <div :title="submission.title"
                         class="text-truncate pr-2">{{submission.title}}</div>
                    <submission-indicators :submission="submission"
                                           show-status></submission-indicators>
                </v-card-title>
                <v-card-subtitle class="d-flex justify-space-between flex-nowrap pt-1">

                    <submitter-details :submitter="submission.submitter"
                                       prefix="By"
                                       class="px-0 mr-2"></submitter-details>
                    <div>
                        <span class="d-none d-md-inline mr-1">Submitted:</span>
                        <span>{{createdDate}}</span>
                    </div>
                </v-card-subtitle>

                <!-- Previous Steps -->
                <template v-if="submission.project.inWorktrays.length > 0">
                    <v-card-subtitle>Currently In Worktrays:</v-card-subtitle>
                    <v-card-text class="d-flex">
                        <submission-in-worktray v-for="inWorktrayDetails in submission.project.inWorktrays"
                                                :key="inWorktrayDetails._id"
                                                :project-id="submission.project._id"
                                                :worktray-details="inWorktrayDetails">
                        </submission-in-worktray>
                    </v-card-text>
                </template>

                <!-- Previous Steps -->
                <template v-if="submission.steps && submission.steps.length > 0">
                    <v-card-subtitle class="pb-0">Steps</v-card-subtitle>
                    <previous-steps-details :previous-steps="submission.steps"
                                            class="mx-2"></previous-steps-details>
                </template>

                <!-- Files -->
                <v-card-subtitle class="pb-0">Files</v-card-subtitle>
                <file-list :files="submission.files"
                           @file-selected="downloadFile"
                           class="mx-4"></file-list>

                <!-- Source -->
                <v-card-subtitle class="pb-0">Source</v-card-subtitle>
                <submission-source-status :source="submission.source"></submission-source-status>

                <!-- Actions -->
                <card-actions>
                    <template #secondary>
                        <v-btn v-if="canDelete"
                               @click="deleteSub()"
                               :loading="loading === 'delete'"
                               :disabled="loading && loading !== 'delete'"
                               text>Delete Submission</v-btn>

                        <v-menu offset-y
                                top
                                v-model="manageWorkflowOpen">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       text
                                       :loading="manageWorkflowLoading"
                                       :disabled="loading && !manageWorkflowLoading">
                                    Mangage Workflow
                                </v-btn>
                            </template>
                            <v-list>
                                <template v-if="hasActiveStep">
                                    <v-subheader class="mb-0">Active Step</v-subheader>
                                    <v-list-item @click.stop="reassign = true; manageWorkflowOpen = false;">
                                        <v-list-item-title>Reassign '{{activeStep.name}}'</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click.stop="activeStepDelete = true; manageWorkflowOpen = false;">
                                        <v-list-item-title>Delete '{{activeStep.name}}'</v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-subheader class="mb-0">Worktrays</v-subheader>
                                </template>
                                <v-list-item @click.stop="worktrayAdd = true; manageWorkflowOpen = false;">
                                    <v-list-item-title>Add to Worktray</v-list-item-title>
                                </v-list-item>
                                <v-list-item :disabled="submission.project.inWorktrays.length === 0"
                                             @click.stop="worktrayRemove = true; manageWorkflowOpen = false;">
                                    <v-list-item-title>Remove from Worktray</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn @click="back()"
                               :disabled="!!loading"
                               text>{{backBtnText}}</v-btn>
                    </template>
                </card-actions>
            </v-card>
        </v-container>

        <div v-else
             class="d-flex align-center"
             style="height: 100%;">
            <div class="mx-auto text-center"
                 style="position: relative; top: -12vh">
                <h2>404 - Not Found</h2>
                <em>The requested submission does not exist..</em>
            </div>
        </div>
        <reassign-dialog v-if="hasActiveStep"
                         :show.sync="reassign"></reassign-dialog>
        <active-step-delete-dialog v-if="hasActiveStep"
                                   :show.sync="activeStepDelete"></active-step-delete-dialog>
        <worktray-add-dialog :show.sync="worktrayAdd"></worktray-add-dialog>
        <worktray-remove-dialog :show.sync="worktrayRemove"></worktray-remove-dialog>
    </app-navigation>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';
    import beforeEnterGuard from '../router/beforeEnterGuard';

    import activeStepDeleteDialog from '@/modules/projects/components/submission-step-delete-dialog';
    import appNavigation from '@/components/app-navigation';
    import fileList from '@/components/file-list';
    import previousStepsDetails from '@/components/submission-previous-steps-details';
    import reassignDialog from '@/modules/projects/components/submission-reassign-dialog';
    import submissionIndicators from '@/components/submission-indicators';
    import submissionInWorktray from '../components/submission-in-worktray';
    import submissionSourceStatus from '@/components/submission-source-status';
    import submitterDetails from '@/components/submitter-details';
    import worktrayAddDialog from '@/modules/projects/components/submission-worktray-add-dialog';
    import worktrayRemoveDialog from '@/modules/projects/components/submission-worktray-remove-dialog';

    export default {
        name: 'submission-view',
        components: {
            activeStepDeleteDialog,
            appNavigation,
            fileList,
            previousStepsDetails,
            reassignDialog,
            submissionIndicators,
            submissionInWorktray,
            submissionSourceStatus,
            submitterDetails,
            worktrayAddDialog,
            worktrayRemoveDialog,
        },
        props: {
            submissionId: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            activeStepDelete: false,
            manageWorkflowOpen: false,
            notFound: false,
            prevRoute: undefined,
            reassign: false,
            worktrayAdd: false,
            worktrayRemove: false,
        }),
        computed: {
            backBtnText() {
                return 'Back' + (this.hasFromName ? '' : ' To Project');
            },
            canDelete() {
                const {
                    source: { type },
                    steps,
                } = this.submission;
                return type === 'Submittable' && steps.length === 0;
            },
            hasActiveStep() {
                return !!this.activeStep;
            },
            createdDate() {
                const sub = this.submission;
                return sub ? this.$displayDate(sub.createdDate) : undefined;
            },
            hasFromName() {
                return this.prevRoute && !!this.prevRoute.name;
            },
            submitter() {
                return this.submission ? this.submission.submitter : undefined;
            },
            manageWorkflowLoading() {
                const l = this.loading;
                return (
                    l === 'reassign' ||
                    l === 'worktrayAdd' ||
                    l === 'activeStepDelete'
                );
            },
            ...mapState('organisation/projects', {
                loading: state => state.activeSubmission.loading,
                submission: state => state.activeSubmission.details,
            }),
            ...mapGetters('organisation/projects', {
                activeStep: 'activeSubActiveStep',
                projectId: 'activeProjectId',
            }),
        },
        methods: {
            back() {
                if (this.hasFromName) return this.$router.go(-1);

                this.$router.push(`/projects/${this.projectId}/worktrays`);
            },
            deleteSub() {
                if (this.canDelete) {
                    return this.$store
                        .dispatch('organisation/projects/activeSubmissionDelete')
                        .then(() => {
                            this.back();
                        });
                }
            },
            downloadFile(fileId) {
                this.$store.dispatch('organisation/projects/fileDownload', {
                    submissionId: this.submissionId,
                    fileId: fileId,
                });
            },
        },
        created() {
            this.$store
                .dispatch('organisation/projects/activeSubmissionLoad', {
                    submissionId: this.submissionId,
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        this.notFound = true;
                        this.$store.commit('updateStatus', { isLoading: false });
                    } else throw err;
                });
        },
        beforeRouteUpdate(to, from, next) {
            return beforeEnterGuard(this.$store, to, from, next(vm => {
                vm.prevRoute = from;
            }), true);
            
        },
    };
</script>