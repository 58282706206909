<template>
    <tr>
        <td class="align-middle max-width-15vw text-truncate"
            :title="file.name">{{file.name}}</td>
        <td>
            <div class="input-group">
                <v-text-field v-model="fileName"
                              :disabled="disabled"
                              solo
                              :hide-details="!fileNameInvalid"
                              :error-messages="fileNameInvalidErr"
                              class="my-2 small-right-padding">
                    <template #append>
                        <v-menu :disabled="disabled">
                            <template v-slot:activator="{ on }">
                                <v-btn :disabled="disabled"
                                       @click="useTitle"
                                       class="d-none d-md-inline-flex">Use Title</v-btn>
                                <v-btn v-on="on"
                                       class="btn-narrow">
                                    <v-icon>expand_more</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="useTitle">
                                    <v-list-item-title>Use Title</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="cleanName">
                                    <v-list-item-title>Clean</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="resetName">
                                    <v-list-item-title>Reset</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-text-field>
            </div>
        </td>
        <td>
            <div class="d-flex">
                <v-btn text
                       @click="downloadFile"
                       fab
                       :loading="downloading"
                       :disabled="file.isPending || uploading"
                       :title="downloadBtnTitle"
                       style="pointer-events: auto;">
                    <!-- This ^ is because they change it and then you can't see the bloody title -->
                    <v-icon>fa-file-download</v-icon>
                </v-btn>
                <v-btn text
                       fab
                       @click="replacementOfferChoice"
                       :loading="uploading"
                       :title="`Replace the file for: '${fileNameWithExtension}'`">
                    <v-icon>fa-file-upload</v-icon>
                </v-btn>
            </div>
            <v-dialog v-model="replacementOffer"
                      width="800">
                <v-card>
                    <v-card-title><span class="mr-1">Replace file for: </span><em> {{this.submission.title}}</em></v-card-title>
                    <v-card-text>
                        <p>Select a file to upload as a replacement file.</p>
                        <v-file-input :label="fileNameWithExtension"
                                      @change="replacementSelect"></v-file-input>
                    </v-card-text>
                    <card-actions>
                        <v-btn color="primary"
                               text
                               @click="replacementUpload">Upload</v-btn>
                        <template #secondary>
                            <v-btn text
                                   @click="replacementClose">Cancel</v-btn>
                        </template>
                    </card-actions>
                </v-card>
            </v-dialog>
        </td>
    </tr>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';

    const seperators = '[- _.+:]*';

    export default {
        name: 'worktray-cleaning-file-row',
        props: {
            file: {
                type: Object,
                required: true,
            },
            submissionId: String,
            disabled: Boolean,
        },
        data() {
            return {
                replacementOffer: false,
                replacementFile: undefined,
                workingFileName: undefined,
            };
        },
        computed: {
            downloadBtnTitle() {
                return this.file.isPending
                    ? 'The file is still being processed and so is not available for download yet. We should have it all ready to serve in a few minutes. Please try again later!'
                    : this.uploading
                    ? 'Please wait for the file to upload before attempting to download it'
                    : `Download: '${this.fileNameWithExtension}'`;
            },
            extension() {
                return this.file.extension;
            },
            fileId() {
                return this.file._id;
            },
            fileName: {
                get() {
                    return (
                        this.workingFileName || this.removeExtension(this.file.name)
                    );
                },
                set(newName) {
                    this.workingFileName = newName;
                    this.$store.commit(
                        'organisation/projects/cleaning/setFileName',
                        {
                            submissionId: this.submissionId,
                            fileId: this.fileId,
                            name: newName,
                            extension: this.extension,
                        }
                    );
                },
            },
            fileNameInvalid() {
                const invalid = this.fileNameInvalidChars.length;
                return invalid;
            },
            fileNameInvalidChars() {
                return this.modifications.invalidChars || [];
            },
            fileNameInvalidErr() {
                return this.fileNameInvalid
                    ? `Cannot contain the following characters: ${this.fileNameInvalidChars.join(
                          ''
                      )}`
                    : null;
            },
            fileNameWithExtension() {
                return `${this.fileName}.${this.extension}`;
            },
            modifications() {
                return this.submissionModifications.files[this.fileId];
            },
            submission() {
                return this.submissionById(this.submissionId);
            },
            ...mapState('files', {
                downloading(state) {
                    return state.downloading[this.fileId];
                },
                uploading(state) {
                    return state.uploading[this.fileId];
                },
            }),
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'submissionById',
            ]),
            ...mapState('organisation/projects/cleaning', {
                submissionModifications(state) {
                    return state[this.submissionId];
                },
            }),
        },
        methods: {
            cleanName() {
                let submitter = this.submission.submitter;
                this.fileName = this.fileName
                    .replace(
                        RegExp(`${seperators}${submitter.firstName}${seperators}`),
                        ''
                    )
                    .replace(
                        RegExp(`${seperators}${submitter.surname}${seperators}`),
                        ''
                    );
            },
            downloadFile() {
                this.$store.dispatch('organisation/projects/fileDownload', {
                    submissionId: this.submissionId,
                    fileId: this.fileId,
                    fileName: this.fileNameWithExtension,
                });
            },
            removeExtension(fileName) {
                if (typeof fileName === 'string')
                    return fileName.replace(`.${this.file.extension}`, '');
            },
            replacementClose() {
                this.replacementOffer = false;
            },
            replacementOfferChoice() {
                this.replacementOffer = true;
            },
            replacementSelect(newFile) {
                this.replacementFile = newFile;
            },
            replacementUpload() {
                this.$store.dispatch(
                    'organisation/projects/activeProjectWorktrays/submissionFileUploadReplacement',
                    {
                        submissionId: this.submission._id,
                        fileId: this.fileId,
                        file: this.replacementFile,
                    }
                );
                this.replacementClose();
            },
            resetName() {
                this.fileName = this.removeExtension(this.file.name);
            },
            useTitle() {
                this.fileName = this.submissionModifications.title;
            },
        },
    };
</script>

<style scoped>
    .btn-narrow {
        min-width: 0 !important;
        padding: 0.1em !important;
    }
</style>
<style>
    .max-width-15vw {
        max-width: 15vw;
    }

    .v-input.small-right-padding .v-input__slot {
        padding-right: 0.33333333em !important;
    }
</style>