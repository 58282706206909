<template>
    <v-card :to="`/projects/${projectId}/worktrays`"
            shaped
            class="mr-3">
        <v-card-title class="font-weight-regular">{{worktrayDetails.name}}</v-card-title>
        <v-card-subtitle>{{worktrayDetails.reasonName}}</v-card-subtitle>
    </v-card>
</template>

<script>
    export default {
        name: 'submission-in-worktray',
        props: {
            projectId: {
                type: String,
                required: true,
            },
            worktrayDetails: {
                type: Object,
                required: true,
            },
        },
    };
</script>