<template>
    <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-card-text>
            <p v-if="accessKeyDuplicate"
               class="mb-0">Someone else has been setting up this account at the same time as you. It seems they got there first and the account has been created. Please check that they set everything up as you expected.</p>
            <template v-else-if="newAccessKey">
                <p>
                    <span>Awesome — that Access Key worked like a charm! We were able to use it to access the Submittable Team: </span>
                    <strong>{{orgName}}</strong>
                    (<a :href="'https://' +submittableOrg.domain"
                       target="_blank">landing page</a>).
                    <span>Hopefully you're expecting to see {{orgName}}, but if not, go ahead and hit "Cancel" to try again.</span>
                </p>
                <p>Once you're happy, you can choose how you would like to refer to {{orgName}} locally (in {{$appName}}) in the below text box and hit "Save" to add the Account.</p>
                <v-text-field v-model.trim="name"
                              v-focus
                              :disabled="loading"
                              label="Local Name"
                              :error-messages="nameValidationMessage"
                              counter="100"
                              maxlength="100">
                </v-text-field>
            </template>
            <template v-else>
                <p>Awesome — that Access Key worked like a charm! It gave us access to Submittable and all the details matched with what we were expecting.</p>
                <p class="mb-0">To finish off, hit "Save" to update {{this.account.name}}.</p>
            </template>
        </v-card-text>
        <card-actions>
            <v-btn v-if="!accessKeyDuplicate"
                   :disabled="!!nameValidationMessage"
                   :loading="loading"
                   text
                   key="save"
                   color="primary"
                   @click="save">Save</v-btn>
            <template #secondary>
                <v-btn text
                       key="cancel-confirm"
                       @click="$emit('cancel')">Cancel</v-btn>
            </template>
        </card-actions>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'access-key-confirm',
        props: {
            accountId: String,
            accessKey: {
                type: String,
                required: true,
            },
            submittableOrg: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                accessKeyDuplicate: false,
                loading: false,
                name: this.submittableOrg.name,
                nameDuplicate: null,
            };
        },
        computed: {
            dispatchAction() {
                return `organisation/submittable/${
                    this.newAccessKey ? 'create' : 'update'
                }Account`;
            },
            nameValidationMessage() {
                return !this.name
                    ? 'A local name is required'
                    : this.nameDuplicate === this.name
                    ? 'An account already exists with this name, please double check you are adding the correct account and try a different name'
                    : null;
            },
            newAccessKey() {
                return !this.accountId;
            },
            orgName() {
                return this.submittableOrg
                    ? this.submittableOrg.name
                    : 'No Organisation Loaded - this is unexpected so please reach out for help!';
            },
            title() {
                return (
                    (this.newAccessKey
                        ? 'New Submittable Account: '
                        : `${this.account.name}: `) + 'Confirm'
                );
            },
            ...mapGetters('organisation/submittable', {
                account(getters) {
                    return this.accountId ? getters.accounts[this.accountId] : null;
                },
            }),
        },
        methods: {
            save() {
                if (!this.nameValidationMessage) {
                    this.loading = true;

                    this.$store
                        .dispatch(this.dispatchAction, {
                            name: this.newAccessKey ? this.name : null,
                            accountId: this.accountId,
                            accessKey: this.accessKey,
                        })
                        .then(() => {
                            this.$emit('exit');
                        })
                        .catch(err => {
                            this.loading = false;
                            const resp = err.response;
                            if (
                                resp &&
                                resp.status === 409 &&
                                resp.data.isDuplicate
                            ) {
                                // Duplicate on name
                                this.nameDuplicate = resp.data.name
                                    ? this.name
                                    : null;

                                // Duplicate on access key
                                this.accessKeyDuplicate =
                                    resp.data.accessKey || false;

                                // Handled situation:
                                if (resp.data.name || resp.data.accessKey) return;
                            }

                            return this.$handleError(err);
                        });
                }
            },
        },
    };
</script>