<template>
    <app-navigation heading="Submissions: Out of Sync ">
        <v-container class="mh-100"
                     :class="{'d-flex align-center justify-center': !loading && !hasSubmissions }">
            <template v-if="hasSubmissions">
                <v-row v-for="sub in submissions"
                       :key="sub._id"
                       justify="center">
                    <v-col cols="12"
                           lg="8">
                        <submission-out-of-sync :submission-id="sub._id"></submission-out-of-sync>
                    </v-col>
                </v-row>
            </template>
            <template v-if="loading">
                <v-row v-for="i in (count || 1) - loadedSubmissionCount"
                       :key="i"
                       justify="center">
                    <v-col cols="12"
                           lg="8">
                        <v-skeleton-loader type="card-heading,text,table-thead,table-row,actions"
                                           class="w-100 mb-4"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </template>
            <div v-if="!hasSubmissions && !loading"
                 class="d-flex align-center justify-center">
                <div>
                    <h2 class="text-center">As far as we know, all submissions statuses are as expected!</h2>
                    <em>It could be possible that a submission has been updated in Submittable but the system isn't aware of this yet.</em>
                </div>
            </div>
        </v-container>
    </app-navigation>
</template>

<script>
    import { mapState } from 'vuex';

    import appNavigation from '@/components/app-navigation';
    import submissionOutOfSync from '@/modules/submittable/components/submission-out-of-sync';

    export default {
        name: 'submissions-syncing',
        components: {
            appNavigation,
            submissionOutOfSync,
        },
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            hasSubmissions() {
                return this.loadedSubmissionCount > 0;
            },
            loadedSubmissionCount() {
                return Object.keys(this.submissions).length;
            },
            ...mapState('organisation/submittable/syncing', [
                'count',
                'submissions',
            ]),
        },
        beforeCreate() {
            this.$store
                .dispatch(
                    'organisation/submittable/syncing/getOutOfSyncSubmissions'
                )
                .then(() => {
                    this.loading = false;
                });
        },
    };
</script>