<template>
    <v-card>
        <v-card-title class="justify-space-between flex-nowrap">
            <router-link :to="`/projects/${activeProjectId}/submissions/${submissionId}`"
                         class="submission-title text-truncate">
                <span :title="`Show details for '${submission.title}'`">{{submission.title}}</span>
            </router-link>
            <submission-indicators :submission="submission"
                                   editable-category>
                <template #pre-category>
                    <reason-chip :reason-id="submission.reasonId"
                                 chip-class="mr-2"></reason-chip>
                </template>
            </submission-indicators>
        </v-card-title>
        <v-card-subtitle class="d-flex justify-space-between flex-nowrap pt-1">
            <div class="text-truncate mr-2"
                 :title="`${submitter.firstName} ${submitter.surname}`">
                <em>by</em> {{submitter.firstName}} {{submitter.surname}}
            </div>
            <div>
                <span class="d-none d-md-inline mr-1">Submitted:</span>
                <span>{{createdDate}}</span>
            </div>
        </v-card-subtitle>
        <v-card-subtitle class="pb-0">Assign to:</v-card-subtitle>
        <v-card-actions>
            <v-chip-group v-model="boundAssigneeId"
                          active-class="primary--text"
                          show-arrows>
                <staff-chip v-for="staffer in staff"
                            :key="staffer._id"
                            :staffer="staffer"
                            :submission-assignee-id="boundAssigneeId"
                            :submission="submission">
                </staff-chip>
            </v-chip-group>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';

    import reasonChip from './worktray-reason-chip';
    import staffChip from './worktray-assign-least-subs-staff-chip';
    import submissionIndicators from '@/components/submission-indicators';

    export default {
        name: 'worktray-assign-card',
        components: {
            reasonChip,
            staffChip,
            submissionIndicators,
        },
        props: {
            submissionId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                submission: null,
            };
        },
        computed: {
            boundAssigneeId: {
                get() {
                    return this.assigneeId;
                },
                set(assigneeId) {
                    this.$store.dispatch('organisation/projects/assigning/assign', {
                        assigneeId,
                        submissionId: this.submission._id,
                    });
                },
            },
            createdDateString() {
                const sub = this.submission;

                return !sub ? undefined : sub.createdDate;
            },
            createdDate() {
                return this.createdDateString
                    ? this.$displayDate(this.createdDateString)
                    : undefined;
            },
            staff() {
                return this.staffOrderedAndAssignableForReason(
                    this.submission.reasonId
                );
            },
            submitter() {
                return this.submission.submitter;
            },
            ...mapState('organisation/projects/assigning', {
                assigneeId(state) {
                    const assignedDetails = state.assigned[this.submissionId];
                    return assignedDetails ? assignedDetails.assigneeId : undefined;
                },
            }),
            ...mapGetters('organisation/projects', ['activeProjectId']),
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'reasons',
                'submissionById',
            ]),
            ...mapGetters('organisation/projects/assigning', [
                'staffLockedForActiveWorktray',
                'staffOrderedAndAssignableForReason',
            ]),
        },
        created() {
            const sub = this.submissionById(this.submissionId);

            if (sub) this.submission = sub;
            else
                this.$store
                    .dispatch(
                        'organisation/projects/activeProjectWorktrays/submissionFetch',
                        {
                            submissionId: this.submissionId,
                        }
                    )
                    .then(sub => {
                        this.submission = sub;
                    });
        },
    };
</script>
<style scoped>
    .submission-title {
        text-decoration: none;
        color: unset;
        padding-right: 0.5em;
    }

    @media (min-width: 1080px) {
        .submission-title {
            padding-right: 4em;
        }
    }
</style>