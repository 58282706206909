<template>
    <div v-if="total > 0"
         :key="categoryId"
         class="d-inline-block"
         :title="title">
        <category-chip :category-id="categoryId"
                       :count="total"
                       :disabled="locked || onlyHasExistingAssignments"
                       @click="unassign()"
                       class="mx-1 mb-2">
            <template v-if="!onlyHasExistingAssignments" 
                      #right>
                <v-icon right small>remove_circle_outline</v-icon>
            </template>
        </category-chip>
    </div>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';

    import categoryChip from '@/components/category-chip';

    export default {
        name: 'worktray-assign-least-subs-staffer-category',
        components: {
            categoryChip,
        },
        props: {
            categoryId: {
                type: String,
                required: true,
            },
            assignedTracker: {
                type: Object,
                required: true,
            },
            staffer: {
                type: Object,
                required: true,
            },
        },
        computed: {
            locked() {
                return !!this.staffLockedForActiveWorktray[this.staffer._id];
            },
            onlyHasExistingAssignments() {
                return (
                    this.assignedTracker.totalIncludesExisting &&
                    this.total === this.assignedTracker.existingAssignments.length
                );
            },
            title() {
                const {
                    category: { name: category },
                    onlyHasExistingAssignments,
                    locked,
                    staffer: { firstName: name },
                    total,
                } = this;

                return locked
                    ? `Submissions cannot be unassigned when ${name} is locked`
                    : onlyHasExistingAssignments
                    ? `${name} has ${total} existing '${category}' submission${
                          total === 1 ? '' : 's'
                      }.`
                    : `Unassign the last '${category}' submission assigned to ${name}.`;
            },
            total() {
                return this.assignedTracker.totalWorking;
            },
            ...mapState('categories', {
                category(state) {
                    return state[this.categoryId] || {};
                },
            }),
            ...mapGetters('organisation/projects/assigning', [
                'staffLockedForActiveWorktray',
            ]),
        },
        methods: {
            unassign() {
                const { draftAssignments } = this.assignedTracker;
                this.$store.commit('organisation/projects/assigning/unassign', {
                    submissionId: draftAssignments[draftAssignments.length - 1],
                });
            },
        },
    };
</script>