<template>
    <worktray-pager>
        <template #submissions="{submissionsPage}">
            <single-action v-for="sub in submissionsPage"
                           :key="sub._id"
                           :submission-id="sub._id"
                           :reason-id="sub.reasonId"></single-action>
        </template>
    </worktray-pager>
</template>
<script>
    import worktrayPager from './worktray-pager';
    import worktraySingleActionCard from './worktray-single-action-card';

    export default {
        name: 'worktray-single-action',
        components: {
            worktrayPager,
            'single-action': worktraySingleActionCard,
        },
    };
</script>