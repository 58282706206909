<template>
    <v-menu v-model="menuOpen"
            offset-y
            :close-on-content-click="false">
        <template #activator="{ on }">
            <v-chip v-on="on"
                    :color="reason.colorHex"
                    :class="chipClass"
                    outlined
                    :small="small">{{reason.name}}</v-chip>
        </template>
        <v-card class="pt-2">
            <v-chip-group v-model="selectedColorBound"
                          mandatory
                          column
                          class="mx-2 small-check"
                          style="max-width: 100px;">
                <v-chip v-for="c in colors"
                        :key="c.hex"
                        small
                        filter
                        :color="c.name"
                        :text-color="textColor"></v-chip>
            </v-chip-group>
        </v-card>
    </v-menu>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { textColorForBg } from '@/helpers/colors';

    const colors = [
        { name: 'grey darken-4', hex: '#212121' },
        { name: 'deep-orange accent-4', hex: '#DD2C00' },
        { name: 'deep-purple accent-4', hex: '#6200EA' },
        { name: 'light-blue darken-3', hex: '#0277BD' },
        { name: 'pink accent-4', hex: '#C51162' },
        { name: 'green darken-1', hex: '#43A047' },
    ];

    export default {
        name: 'worktray-reason-chip',
        props: {
            chipClass: String,
            reasonId: {
                type: Number,
                required: true,
            },
            small: Boolean,
        },
        data() {
            return {
                colors,
                menuOpen: false,
                selectedColor: 0,
            };
        },
        computed: {
            colorBound: {
                get() {
                    return this.colors[this.selectedColor];
                },
            },
            colorHex() {
                const hext = this.colorBound.hex;
                colors.find(f => f.hex === hext);
                this.selected;
                return this.colorBound.hex;
            },
            reason() {
                return this.reasons[this.reasonId];
            },
            reasonName() {
                return this.reason.name;
            },
            selectedColorBound: {
                get() {
                    return this.selectedColor;
                },
                set(newColorIndex) {
                    this.selectedColor = newColorIndex;
                    this.$store.dispatch(
                        'organisation/projects/activeProjectWorktrays/setReasonColor',
                        {
                            reasonId: this.reasonId,
                            colorHex: this.colorHex,
                        }
                    );
                },
            },
            textColor() {
                return textColorForBg(this.colorHex);
            },
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'reasons',
            ]),
        },
        created() {
            const color = colors.find(f => f.hex === this.reason.colorHex);
            if (color) {
                this.selectedColor = colors.indexOf(color);
            }
        },
    };
</script>