<template>
    <v-tooltip v-if="lastAssignedDates"
               bottom
               color="grey darken-3">
        <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-history</v-icon>
        </template>
        <h4 class="mb-1">Last Assigned Dates:</h4>
        <table>
            <tbody>
                <tr v-for="details in displayDetails"
                    :key="details.reasonId">
                    <td class="pr-2">{{details.name}}:</td>
                    <td>{{details.date}}</td>
                </tr>
            </tbody>
        </table>
    </v-tooltip>
</template>
<script>
    import _ from 'lodash';
    import { mapGetters } from 'vuex';

    export default {
        name: 'worktray-assign-last-assign-details',
        props: {
            assigneeId: {
                type: String,
                required: true,
            },
        },
        computed: {
            displayDetails() {
                const reasons = this.worktrayActiveReasons;
                return !this.lastAssignedDates
                    ? undefined
                    : _.transform(
                          this.lastAssignedDates,
                          (details, dateInt, reasonId) => {
                              details.push({
                                  reasonId,
                                  name: reasons[reasonId].name,
                                  date: this.$displayDate(dateInt),
                              });
                              return details;
                          },
                          []
                      );
            },
            lastAssignedDates() {
                return this.staffLastAssignedDates[this.assigneeId];
            },
            ...mapGetters('organisation/projects/assigning', [
                'staffLastAssignedDates',
                'worktrayActiveReasons',
            ]),
        },
    };
</script>
<!-- This is a bit crap, but vueitfy doesn't allow you to choose opacity for your tooltips -->
<style>
    .v-tooltip__content.menuable__content__active {
        opacity: 1 !important;
    }
</style>