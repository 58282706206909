<template>
    <tbody>
        <tr>
            <th colspan="6">
                <div>{{staffer.firstName}} {{staffer.surname}}</div>
            </th>
        </tr>
        <tr v-if="assignments.length===0"
            class="no-assignments">
            <td colspan="6"><em>You have not assigned {{staffer.firstName}} any submissions for this round.</em></td>
        </tr>
        <tr v-else
            v-for="(sub, i) in assignments"
            :key="sub._id"
            class="assignment">
            <td>{{i+1}}</td>
            <td>
                <em>{{sub.title}}</em>
            </td>
            <td>
                <submission-flag v-if="sub.flagged"></submission-flag>
            </td>
            <td>
                <reason-chip :reason-id="sub.reasonId"
                             small
                             chip-class="mr-2"></reason-chip>
            </td>
            <td>
                <category-chip :category-id="sub.categoryId"
                               small="small"></category-chip>
            </td>
            <td>
                <v-icon v-if="assigned"
                        color="green">done</v-icon>
                <v-progress-circular v-else
                                     indeterminate
                                     size="24"
                                     width="2"></v-progress-circular>
            </td>
        </tr>
        <tr v-if="stafferAssignments.total !== 0"
            class="summary">
            <td colspan="2"></td>
            <td><em>Total Assigned</em></td>
            <td colspan="2">
                <category-chip v-for="({totalWorking}, catId) in assignmentList"
                               :key="catId"
                               :category-id="catId"
                               :count="totalWorking"
                               small
                               class="mr-2 py-3"></category-chip>
            </td>
            <td></td>
        </tr>
    </tbody>
</template>
<script>
    import { mapGetters } from 'vuex';

    import categoryChip from '@/components/category-chip';
    import reasonChip from './worktray-reason-chip';
    import submissionFlag from '@/components/submission-flag';

    export default {
        name: 'worktray-assign-confirm-staffer',
        components: {
            categoryChip,
            reasonChip,
            submissionFlag,
        },
        props: {
            staffer: {
                type: Object,
                required: true,
            },
            assigned: Boolean,
        },
        data: () => ({
            assignments: null,
        }),
        computed: {
            assignmentList() {
                const val = { ...this.stafferAssignments };
                delete val.total;
                return val;
            },
            stafferAssignments() {
                return this.staffer.assigned;
            },
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'submissionById',
            ]),
        },
        created() {
            this.assignments = Object.values(this.assignmentList)
                .reduce((all, v) => [...all, ...v.draftAssignments], [])
                .map(subId => this.submissionById(subId))
                .map(sub => ({
                    _id: sub._id,
                    title: sub.title,
                    flagged: sub.flaggedWarning,
                    reasonId: sub.reasonId,
                    categoryId: sub.category._id,
                }));
        },
    };
</script>
<style scoped>
    td,
    th {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    /** Header Row */
    th {
        padding-bottom: 0.5em;
        padding-top: 1em;
    }
    th div {
        background-color: #e1f5fe;
        padding: 0.2em;
        border-radius: 1em;
    }

    /** Submission List */
    tr td:first-child {
        padding-right: 0.5em;
    }

    tbody tr:nth-child(2) td {
        padding-top: 0.25em;
    }

    tbody tr:nth-last-child(2) td {
        padding-bottom: 0.25em;
    }

    tr.no-assignments td {
        text-align: center;
        padding-bottom: 1.25em !important;
    }

    tr.assignment td:nth-child(2) em {
        display: block;
        width: 30vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    tr.assignment td:nth-child(3) {
        text-align: center;
    }
    tr.assignment td:nth-child(4) {
        text-align: end;
    }

    tr.assignment td:last-child {
        opacity: 0;
    }

    table.loading tr.assignment td:last-child {
        opacity: 1;
    }

    /** Summary Row */
    tr.summary td:nth-child(2) {
        padding-right: 0.5em;
        text-align: end;
    }
    tr.summary td:nth-child(3) {
        text-align: center;
        padding: 0.75em 0;
        border: 1px solid #d1d1d1;
        border-left: 0;
        border-right: 0;
    }
</style>