<template>
    <confirm-assignments v-if="confirmAssignments"></confirm-assignments>
    <least-subs-assign v-else-if="method === 'leastSubs'"></least-subs-assign>
    <simple-assign v-else></simple-assign>
</template>
<script>
    import { mapState } from 'vuex';

    import confirmAssignments from './worktray-assign-confirm';
    import leastSubsAssign from './worktray-assign-least-subs';
    import simpleAssign from './worktray-assign-simple';

    export default {
        name: 'worktray-assign',
        components: { confirmAssignments, leastSubsAssign, simpleAssign },
        data: () => ({
            undoListener: undefined,
        }),
        computed: {
            ...mapState('organisation/projects/assigning', [
                'confirmAssignments',
                'method',
            ]),
        },
        created() {
            this.undoListener = e => {
                if (e.keyCode == 90 && e.ctrlKey) {
                    this.$store.dispatch(
                        'organisation/projects/assigning/undoLastAssign'
                    );
                    e.preventDefault();
                }
            };

            window.addEventListener('keydown', this.undoListener);
        },
        destroyed() {
            this.$store.commit('organisation/projects/assigning/clearHistory');
            window.removeEventListener('keydown', this.undoListener);
        },
    };
</script>