<template>
    <v-dialog v-model="showBound"
              max-width="615">
        <v-card>
            <v-card-title class="headline">Are you sure you want to delete the active step?</v-card-title>
            <v-card-text>
                <v-alert type="warning"
                         class="mb-0">Be careful, you could mess up the workflow if this is handled incorrectly.</v-alert>
            </v-card-text>
            <card-actions>
                <v-btn color="primary"
                       text
                       @click="deleteActiveStep()">
                    Delete
                </v-btn>
                <template #secondary>
                    <v-btn text
                           @click="showBound = false">
                        Cancel
                    </v-btn>
                </template>
            </card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'submission-worktray-add-dialog',
        props: {
            show: Boolean,
        },
        computed: {
            showBound: {
                get() {
                    return this.show;
                },
                set(newVal) {
                    this.$emit('update:show', newVal);
                },
            },
        },
        methods: {
            deleteActiveStep() {
                this.$store.dispatch(
                    'organisation/projects/activeSubmissionActiveStepDelete'
                );

                this.showBound = false;
            },
        },
    };
</script>