<template>
    <v-card-text>
        <p>The Access Key is secret password can be used to gain access to team account's data in Submittable.</p>
        <p class="mb-1">To get your access key:</p>
        <ol class="ml-2">
            <li>In Submittable, select <em>More > Account</em> from the top menu bar.</li>
            <li>When the page loads, select <em>API Access</em> from the left-hand side of the screen.</li>
            <li>If API Access is disabled, click the button "Enable API Access"</li>
            <li>Copy the characters titled <em>Your API key</em> (e.g. <em>467edb9e3c2d4746958d246881d20bc5</em>) so that you can enter them here</li>
        </ol>
        <p class="mt-3"><b>WARNING:</b> Anyone that you share this secret with will be able to access all of your team's submittable data. Please share with due care!</p>
        <p class="mb-0">If you think someone has managed to get access to your API Key and you would like to stop them accessing your data, you can regenerate the key or Disable the API access on the same screen as above.</p>
    </v-card-text>
</template>
<script>
    export default {
        name: 'access-key-help',
    };
</script>