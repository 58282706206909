<template>
    <v-dialog v-model="showBound"
              max-width="615">
        <v-card>
            <v-card-title class="headline">Add to worktray</v-card-title>
            <v-card-text v-if="activeProject">
                <p class="mb-2">You can add this submission to a worktray by choosing the details below.</p>

                <v-alert type="warning">Be careful, you could mess up the workflow if this is handled incorrectly.</v-alert>

                <v-select v-model="worktrayId"
                          :items="worktrays"
                          item-value="_id"
                          item-text="name"
                          label="Worktray"
                          hide-details></v-select>

                <v-select v-if="worktray"
                          v-model="reasonId"
                          :items="worktray.reasons"
                          :disabled="worktray.reasons.length === 1"
                          item-value="_id"
                          item-text="name"
                          label="Reason"
                          class="mt-3"
                          hide-details></v-select>
            </v-card-text>
            <card-actions>
                <v-btn color="primary"
                       text
                       :disabled="!canAdd"
                       @click="addToWorktray()">
                    Add to Worktray
                </v-btn>
                <template #secondary>
                    <v-btn text
                           @click="showBound = false">
                        Cancel
                    </v-btn>
                </template>
            </card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';
    export default {
        name: 'submission-worktray-add-dialog',
        props: {
            show: Boolean,
        },
        data: () => ({
            reasonId: undefined,
            worktrayId: undefined,
        }),
        computed: {
            canAdd() {
                return !!this.worktrayId && !!this.reasonId;
            },
            showBound: {
                get() {
                    return this.show;
                },
                set(newVal) {
                    this.$emit('update:show', newVal);
                },
            },
            worktray() {
                return this.worktrayId ? this.getChosenWorktray() : undefined;
            },
            worktrays() {
                return this.activeProject.worktrays.map(id =>
                    this.worktrayById(id)
                );
            },
            ...mapState('organisation/projects', ['activeProject']),
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'worktrayById',
            ]),
        },
        methods: {
            getChosenWorktray() {
                const worktray = this.worktrayById(this.worktrayId);

                this.reasonId =
                    worktray && worktray.reasons.length === 1
                        ? worktray.reasons[0]._id
                        : undefined;

                return worktray;
            },

            addToWorktray() {
                if (this.worktrayId && this.reasonId) {
                    this.$store
                        .dispatch(
                            'organisation/projects/activeSubmissionWorktrayAdd',
                            {
                                worktrayId: this.worktrayId,
                                reasonId: this.reasonId,
                            }
                        )
                        .then(() => {
                            this.worktrayId = undefined;
                        });
                    this.showBound = false;
                }
            },
        },
    };
</script>