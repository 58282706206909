<template>
    <worktray-pager>
        <template #submissions="{submissionsPage}">
            <clean-submission v-for="sub in submissionsPage"
                              :key="sub._id"
                              :submission-id="sub._id"></clean-submission>
        </template>
    </worktray-pager>
</template>
<script>
    import worktrayCleaningCard from './worktray-cleaning-card';
    import worktrayPager from './worktray-pager';

    export default {
        name: 'worktray-cleaning',
        components: {
            'clean-submission': worktrayCleaningCard,
            worktrayPager,
        },
    };
</script>