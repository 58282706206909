<template>
    <v-text-field v-model="boundValue"
                  hide-details
                  class="card-title-input"
                  :disabled="disabled"
                  :error="error"
                  :readonly="readonly"></v-text-field>
</template>
<script>
    export default {
        name: 'card-title-text-field',
        props: {
            value: String,
            disabled: Boolean,
            error: Boolean,
            readonly: Boolean,
        },
        computed: {
            boundValue: {
                get() {
                    return this.value;
                },
                set(newVal) {
                    this.$emit('input', newVal);
                },
            },
        },
    };
</script>
<style>
    .card-title-input {
        padding-top: 0;
        margin-top: 0;
        margin-right: 0.66em;
        font-size: 1.2rem;
    }
    .card-title-input .v-input input {
        max-height: unset;
    }
</style>