<template>
    <v-col cols="12"
           md="11"
           lg="9"
           xl="7">
        <v-card v-if="submission">
            <div class="stick-to-header">
                <v-card-title class="justify-space-between flex-nowrap pb-1">
                    <card-title-text-field v-model="boundTitle"
                                           :title="submission.title"
                                           :disabled="loading"
                                           :error="!boundTitle"></card-title-text-field>
                    <submission-indicators :submission="submission"
                                           editable-category></submission-indicators>
                </v-card-title>
            </div>
            <v-card-subtitle class="d-flex justify-space-between flex-nowrap pt-1">
                <div class="text-truncate mr-2"
                     :title="`${submitter.firstName} ${submitter.surname}`">
                    <em>by</em> {{submitter.firstName}} {{submitter.surname}}
                </div>
                <div>
                    <span class="d-none d-md-inline mr-1">Submitted:</span>
                    <span>{{createdDate}}</span>
                </div>
            </v-card-subtitle>
            <v-card-subtitle class="pb-0">Source</v-card-subtitle>
            <submission-source :source="submission.source"></submission-source>
            <v-card-text>
                <file-cleaning :submission-id="submissionId"></file-cleaning>
            </v-card-text>
            <card-actions>
                <v-btn @click="processSub"
                       color="primary"
                       :loading="loading"
                       :disabled="modifications.isInvalid"
                       text>Process</v-btn>
                <template #secondary>
                    <v-btn :to="`/projects/${activeProjectId}/submissions/${submissionId}`"
                           text>More Details</v-btn>
                </template>
            </card-actions>
        </v-card>
        <submission-skeleton v-else></submission-skeleton>
    </v-col>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    import cardTitleTextField from '@/components/card-title-text-field';
    import fileCleaning from './worktray-cleaning-files';
    import submissionIndicators from '@/components/submission-indicators';
    import submissionSkeleton from '@/components/submission-details-skeleton';
    import submissionSource from '@/components/submission-source-status';

    export default {
        name: 'worktray-cleaning-card',
        components: {
            cardTitleTextField,
            fileCleaning,
            submissionIndicators,
            submissionSkeleton,
            submissionSource,
        },
        props: {
            submissionId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                submission: undefined,
            };
        },
        computed: {
            boundTitle: {
                get() {
                    return this.modifications.title;
                },
                set(newTitle) {
                    this.$store.commit('organisation/projects/cleaning/setTitle', {
                        submissionId: this.submissionId,
                        title: newTitle,
                    });
                },
            },
            createdDateString() {
                const sub = this.submission;

                return !sub ? undefined : sub.createdDate;
            },
            createdDate() {
                return this.createdDateString
                    ? this.$displayDate(this.createdDateString)
                    : undefined;
            },
            submitter() {
                return this.submission ? this.submission.submitter : null;
            },
            ...mapState('organisation/projects/cleaning', {
                modifications(state) {
                    return state[this.submissionId];
                },
            }),
            ...mapGetters('organisation/projects', ['activeProjectId']),
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'submissionById',
            ]),
        },
        methods: {
            processSub() {
                if (this.modifications.isInvalid) return;

                this.loading = true;
                this.$store
                    .dispatch('organisation/projects/cleaning/processSubmission', {
                        submissionId: this.submission._id,
                    })
                    .then(() => {
                        this.loading = false;
                    })
                    .catch(() => {
                        // TODO: Error Handling??
                        this.loading = false;
                    });
            },
        },
        created() {
            const submission = this.submissionById(this.submissionId);

            if (submission) {
                this.submission = submission;
                this.$store.commit(
                    'organisation/projects/cleaning/ensureSubmission',
                    { submission }
                );
            } else
                this.$store
                    .dispatch(
                        'organisation/projects/activeProjectWorktrays/submissionFetch',
                        {
                            submissionId: this.submissionId,
                        }
                    )
                    .then(submission => {
                        this.submission = submission;
                        this.$store.commit(
                            'organisation/projects/cleaning/ensureSubmission',
                            { submission }
                        );
                    });
        },
    };
</script>

<style scoped>
    .max-width-15 {
        max-width: 15vw;
    }

    .stick-to-header {
        background-color: white;
        z-index: 2;
    }
</style>