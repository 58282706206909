<template>
    <v-dialog v-model="showBound"
              max-width="500">
        <v-card>
            <v-card-title class="headline">Reassign '{{activeStep.name}}'?</v-card-title>
            <v-card-text>
                <p class="mb-2">You can reassign who is working on the step '{{activeStep.name}}' by selecting a new assignee and then clicking Reassign.</p>
                <v-select v-model="reassignId"
                          :items="reassignableStaff"
                          item-value="_id"
                          :item-text="u => `${u.firstName} ${u.surname}`"
                          label="New Assignee"
                          hide-details></v-select>
            </v-card-text>
            <card-actions>
                <v-btn color="primary"
                       text
                       :disabled="!reassignId"
                       @click="reassignSub()">
                    Reassign
                </v-btn>
                <template #secondary>
                    <v-btn text
                           @click="showBound = false">
                        Cancel
                    </v-btn>
                </template>
            </card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'submission-reassign-dialog',
        props: {
            show: Boolean,
        },
        data: () => ({
            reassignId: undefined,
        }),
        computed: {
            showBound: {
                get() {
                    return this.show;
                },
                set(newVal) {
                    this.$emit('update:show', newVal);
                },
            },
            reassignableStaff() {
                return this.staffAllAssignableByName.filter(
                    s => s._id !== this.activeStep.assignee._id
                );
            },
            ...mapGetters('organisation/projects', {
                activeStep: 'activeSubActiveStep',
            }),
            ...mapGetters('organisation/projects/assigning', [
                'staffAllAssignableByName',
            ]),
        },
        methods: {
            reassignSub() {
                if (this.reassignId) {
                    this.$store
                        .dispatch(
                            'organisation/projects/activeSubmissionReassign',
                            {
                                newAssigneeId: this.reassignId,
                            }
                        )
                        .then(() => {
                            this.reassignId = undefined;
                        });
                    this.showBound = false;
                }
            },
        },
    };
</script>