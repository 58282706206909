<template>
    <v-card>
        <v-card-title class="justify-space-between">
            <div v-if="nextSubmissionTitle && !wasAssignedNext && !lockedBound"
                 @click="assignNext"
                 class="csr-pointer"
                 :title="`Assign '${nextSubmissionTitle}' to ${firstName}`">{{firstName}} {{staffer.surname}}</div>
            <div v-else
                 :title="wasAssignedNext ? `${firstName} has already been assigned '${nextSubmissionTitle}'` : ''">{{firstName}} {{staffer.surname}}</div>
            <div class="d-flex">
                <last-assign-details :assignee-id="staffer._id"
                                     class="d-inline-block mr-5"></last-assign-details>
                <div :title="lockMessage"
                     class="d-inline-block">
                    <v-switch v-model="lockedBound"
                              hide-details
                              append-icon="mdi-lock"
                              class="mt-0 pt-0"></v-switch>
                </div>
            </div>
        </v-card-title>
        <v-card-subtitle v-if="hasAssignments"
                         class="pb-2">Assigned submissions:</v-card-subtitle>
        <v-card-subtitle v-else
                         class="pb-0">No submissions assigned.</v-card-subtitle>
        <v-card-text>
            <template v-for="(assignedTracker, categoryId) in staffer.assigned">
                <category-tracker v-if="categoryId !== 'total'"
                                  :key="categoryId"
                                  :category-id="categoryId"
                                  :assigned-tracker="assignedTracker"
                                  :staffer="staffer"></category-tracker>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';

    import changeNounToPossessive from '@/helpers/changeNounToPossessive';
    import categoryTracker from './worktray-assign-least-subs-staffer-category';
    import lastAssignDetails from './worktray-assign-last-assign-details';

    export default {
        name: 'worktray-assign-least-subs-staffer',
        components: {
            categoryTracker,
            lastAssignDetails,
        },
        props: {
            staffer: {
                type: Object,
                required: true,
            },
        },
        computed: {
            assigned() {
                return this.staffer.assigned;
            },
            firstName() {
                return this.staffer.firstName;
            },
            hasAssignments() {
                return this.assigned.total > 0;
            },
            lockedBound: {
                get() {
                    return this.staffLockedForActiveWorktray[this.staffer._id];
                },
                set(isLocked) {
                    this.$store.dispatch(
                        'organisation/projects/assigning/setStaffIsLockedForActiveWorktray',
                        {
                            staffId: this.staffId,
                            isLocked,
                        }
                    );
                    this.$emit('is-locked', isLocked);
                },
            },
            lockMessage() {
                const locked = this.lockedBound;
                const lockedWord = locked ? 'Unlock' : 'Lock';

                return `${lockedWord} ${
                    this.possesiveFirstName
                } submissions. When ${lockedWord.toLowerCase()}ed, submissions ${
                    locked ? 'can' : 'cannot'
                } be assigned to/unnasigned from ${this.firstName}.`;
            },
            nextSubmissionTitle() {
                return this.nextSubmission ? this.nextSubmission.title : undefined;
            },
            possesiveFirstName() {
                return changeNounToPossessive(this.firstName);
            },
            staffId() {
                return this.staffer._id;
            },
            wasAssignedNext() {
                return (
                    !this.nextSubmission ||
                    this.stafferWasAlreadyAssignedInThisWorktray(
                        this.staffer._id,
                        this.nextSubmission
                    )
                );
            },
            ...mapState(['categories']),
            ...mapGetters('organisation/projects/assigning', [
                'getSubmission',
                'nextSubmission',
                'staffLockedForActiveWorktray',
                'stafferWasAlreadyAssignedInThisWorktray',
            ]),
        },
        methods: {
            assignNext() {
                this.$store.dispatch('organisation/projects/assigning/assignNext', {
                    assigneeId: this.staffId,
                });
            },
        },
    };
</script>