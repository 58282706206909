<template>
    <v-card>
        <v-card-title class="justify-space-between flex-nowrap">
            <div class="text-truncate pr-2"
                 :title="submission.title">{{submission.title}}</div>
            <status-indicator :status="submission.status"
                              small
                              title="The current status of the submission"></status-indicator>
        </v-card-title>
        <v-card-subtitle class="d-flex">
            <span>{{submission.project.name}}</span>
            <v-spacer></v-spacer>
            <span v-if="submission.assignee"><em>Current Assignee:</em> {{submission.assignee.firstName}} {{submission.assignee.surname}}</span>
        </v-card-subtitle>
        <submission-source-status :source="submission.source"
                                  class="pb-0"></submission-source-status>
        <card-actions>
            <v-btn :loading="resyncing"
                   @click="resyncSubmission(submission._id)"
                   color="primary"
                   text>Resync</v-btn>
        </card-actions>
    </v-card>
</template>
<script>
    import { mapState } from 'vuex';

    import submissionSourceStatus from '@/components/submission-source-status';
    import statusIndicator from '@/components/status-indicator';

    export default {
        name: 'submission-out-of-sync',
        components: {
            statusIndicator,
            submissionSourceStatus,
        },
        props: {
            submissionId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                resyncing: false,
            };
        },
        computed: {
            ...mapState('organisation/submittable/syncing', {
                submission: function(state) {
                    return state.submissions[this.submissionId];
                },
            }),
        },
        methods: {
            resyncSubmission(submissionId) {
                this.resyncing = true;
                this.$store
                    .dispatch('organisation/submittable/syncing/resyncSubmission', {
                        submissionId,
                    })
                    .then(() => {
                        this.resyncing = false;
                    });
            },
        },
    };
</script>