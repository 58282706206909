<template>
    <v-container>
        <v-row justify="center">
            <v-col v-for="sub in activeWorktray.items"
                   :key="sub._id"
                   cols="12"
                   md="8">
                <submission-card :submission-id="sub._id"></submission-card>
            </v-col>
        </v-row>
        <worktray-filters :assign-method.sync="assignMethodBound">
        </worktray-filters>
    </v-container>
</template>
<script>
    import { mapState } from 'vuex';

    import submissionCard from './worktray-assign-simple-card';
    import worktrayFilters from './worktray-assign-filters';

    export default {
        name: 'worktray-assign-simple',
        components: {
            submissionCard,
            worktrayFilters,
        },
        props: {
            assignMethod: String,
        },
        computed: {
            assignMethodBound: {
                get() {
                    return this.assignMethod;
                },
                set(newMethod) {
                    this.$emit('update:assignMethod', newMethod);
                },
            },
            ...mapState('organisation/projects/activeProjectWorktrays', [
                'activeWorktray',
            ]),
        },
    };
</script>