<template>
    <app-navigation :heading="`${activeProject.name}: Submissions with issues`"
                    class="mb-4">
        <template #toolbar>
            <v-spacer></v-spacer>
            <view-selector :project-id="activeProject._id"></view-selector>
        </template>
        <v-container class="mh-100"
                     :class="{'d-flex align-center justify-center': !submissions || !submissions.length }">
            <v-row v-if="submissions && submissions.length"
                   justify="center">
                <v-col v-for="sub in submissions"
                       :key="sub._id"
                       cols="12"
                       md="11"
                       lg="9"
                       xl="7">
                    <v-card :to="`/projects/${activeProject._id}/submissions/${sub._id}`">
                        <v-card-title class="justify-space-between flex-nowrap">
                            <div :title="sub.title"
                                 class="text-truncate pr-2">{{sub.title}}</div>
                            <submission-indicators :submission="sub"></submission-indicators>
                        </v-card-title>
                        <v-card-text v-for="(issue, index) in sub.issues.filter(i => !i.resolved)"
                                     :key="index">{{issue.description}}</v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <div v-else
                 class="d-flex align-center justify-center">
                <div>
                    <h2 class="text-center">There are no submissions with issues!</h2>
                    <em>Hurray!</em>
                </div>
            </div>
        </v-container>
    </app-navigation>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';
    import beforeEnterGuard from '../router/beforeEnterGuard';

    import appNavigation from '@/components/app-navigation';
    import submissionIndicators from '@/components/submission-indicators';
    import viewSelector from '@/modules/projects/components/view-selector';

    export default {
        name: 'submissions-with-issues',
        components: {
            appNavigation,
            submissionIndicators,
            viewSelector,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            submissions: undefined,
        }),
        computed: {
            ...mapState('organisation/projects', ['activeProject']),
            ...mapGetters('organisation/projects', ['activeProjectUrl']),
        },
        methods: {
            loadSubmissions() {
                this.$store.commit('updateStatus', { isLoading: true });
                this.$http
                    .get(`${this.activeProjectUrl}/submissions/issues`)
                    .then(({ data: subs }) => {
                        this.submissions = subs;
                        this.$store.commit('updateStatus', { isLoading: false });
                    });
            },
        },
        beforeRouteUpdate (to, from, next) {
            return beforeEnterGuard(this.$store, to, from, next, true);
        },
        created() {
            this.loadSubmissions();
        },
    };
</script>