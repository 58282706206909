<template>
    <v-col cols="12"
           md="11"
           lg="9"
           xl="7">
        <submission-details v-if="submission"
                            :submission="submission"
                            @download-file="downloadFile"
                            :show-files="components.files"
                            :show-previous-steps="components.previousSteps"
                            :show-source="components.source"
                            :show-submitter="components.submitterDetails">
            <template v-if="worktrayHasGt1Reasons"
                      #submission-indicators-pre-category>
                <reason-chip :reason-id="reasonId"
                             chip-class="mr-2"></reason-chip>
            </template>
            <template #actions>
                <v-btn @click="processSub"
                       color="primary"
                       text
                       :loading="loading">{{activeWorktray.actionName}}</v-btn>
            </template>
            <template #secondary>
                <v-btn :to="`/projects/${activeProjectId}/submissions/${submissionId}`"
                       text>More Details</v-btn>
            </template>
        </submission-details>
        <submission-skeleton v-else></submission-skeleton>
    </v-col>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';

    import reasonChip from './worktray-reason-chip';
    import submissionDetails from '@/components/submission-details';
    import submissionSkeleton from '@/components/submission-details-skeleton';

    export default {
        name: 'worktray-single-action-card',
        components: {
            reasonChip,
            submissionDetails,
            submissionSkeleton,
        },
        props: {
            reasonId: {
                type: Number,
                required: true,
            },
            submissionId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                submission: null,
            };
        },
        computed: {
            components() {
                return this.activeWorktray.uiComponents || {};
            },
            worktrayHasGt1Reasons() {
                return this.activeWorktray.reasons.length > 1;
            },
            ...mapState('organisation/projects/activeProjectWorktrays', [
                'activeWorktray',
            ]),
            ...mapGetters('organisation/projects', ['activeProjectId']),
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'submissionById',
            ]),
        },
        methods: {
            downloadFile(fileId) {
                this.$store.dispatch('organisation/projects/fileDownload', {
                    submissionId: this.submissionId,
                    fileId: fileId,
                });
            },
            processSub() {
                this.loading = true;
                this.$store
                    .dispatch(
                        'organisation/projects/activeProjectWorktrays/processSubmission',
                        { submissionId: this.submission._id }
                    )
                    .then(() => {
                        this.loading = false;
                    })
                    .catch(() => {
                        // TODO: Error Handling??
                        this.loading = false;
                    });
            },
        },
        created() {
            const sub = this.submissionById(this.submissionId);

            if (sub) this.submission = sub;
            else
                this.$store
                    .dispatch(
                        'organisation/projects/activeProjectWorktrays/submissionFetch',
                        {
                            submissionId: this.submissionId,
                        }
                    )
                    .then(sub => {
                        this.submission = sub;
                    });
        },
    };
</script>