<template>
    <v-dialog v-model="showBound"
              max-width="615">
        <v-card>
            <v-card-title class="headline">Remove from worktray</v-card-title>
            <v-card-text v-if="submission">
                <p class="mb-2">You can add remove this submission from a worktray by choosing it below.</p>

                <v-alert type="warning">Be careful, you could mess up the workflow if this is handled incorrectly.</v-alert>

                <v-select v-model="detailsId"
                          :items="worktrays"
                          item-value="_id"
                          :item-text="w => `${w.name} (${w.reasonName})`"
                          label="Worktray"
                          hide-details></v-select>
            </v-card-text>
            <card-actions>
                <v-btn color="primary"
                       text
                       :disabled="!detailsId"
                       @click="removeFromWorktray()">
                    Remove from Worktray
                </v-btn>
                <template #secondary>
                    <v-btn text
                           @click="showBound = false">
                        Cancel
                    </v-btn>
                </template>
            </card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        name: 'submission-worktray-remove-dialog',
        props: {
            show: Boolean,
        },
        data: () => ({
            detailsId: undefined,
        }),
        computed: {
            canAdd() {
                return !!this.worktrayId;
            },
            showBound: {
                get() {
                    return this.show;
                },
                set(newVal) {
                    this.$emit('update:show', newVal);
                },
            },
            worktrays() {
                return this.submission.project.inWorktrays;
            },
            ...mapState('organisation/projects', {
                submission: state => state.activeSubmission.details,
            }),
        },
        methods: {
            removeFromWorktray() {
                if (this.detailsId) {
                    this.$store
                        .dispatch(
                            'organisation/projects/activeSubmissionWorktrayRemove',
                            {
                                worktrayDetailsId: this.detailsId,
                            }
                        )
                        .then(() => {
                            this.detailsId = undefined;
                        });
                    this.showBound = false;
                }
            },
        },
    };
</script>