<template>
    <v-btn v-if="!showHelp"
           fixed
           dark
           fab
           bottom
           right
           color="teal"
           @click="showHelp = true">
        <v-icon>fa-question</v-icon>
    </v-btn>
    <v-dialog v-else
              v-model="showHelp"
              max-width="50%">
        <v-card>
            <v-toolbar flat
                       color="teal"
                       dark>
                <v-toolbar-title>Submittable Accounts</v-toolbar-title>
            </v-toolbar>
            <v-tabs vertical
                    v-model="tab">
                <v-tab>
                    General
                </v-tab>
                <v-tab>
                    Account Name
                </v-tab>
                <v-tab>
                    Access Key
                </v-tab>
                <v-tab>
                    Status
                </v-tab>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <p>In order to manage and import active submissions from Submittable, we need to be able to access their data.</p>
                            <p>On this page, you can maintain the link with the Submittable team accounts that you wish to manage.</p>
                            <p>To add a new account, click the green plus at the bottom of the table.</p>
                            <p class="mb-0">Similarly, you can delete a team account by clicking the trash icon on the right-hand side of its record in the table. Deleting an account will not delete any data we have stored, but will mean that the submittable account will not be connected to. </p>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <p>The Account Name is a way of identifying a Submittable team account within this application.</p>
                            <p>There are no restrictions on the name but, for simiplicity, we suggest choosing the same name you chose for the team account in Submittable.</p>
                            <p class="mb-0">The name of the team account is displayed as the main header on the submissions page in Submittable.</p>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <accessKeyHelp></accessKeyHelp>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <p>The current status of the connection is displayed as part of the table.</p>
                            <p><strong>Test Connection:</strong> Once you are happy with the details that you entered, click the button to test the connection. If a connection could not be established, a warning will be displayed.</p>
                            <p><strong>Active:</strong> Once a connection has been successfully tested, you can mark an account as active, which will expose it for management within the application. Once active, the details will not be able to be updated unless the account is marked as inactive again.</p>
                            <p class="mb-0">
                            </p>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
            <card-actions>
                <v-btn color="primary"
                       @click="showHelp = false">OK</v-btn>
            </card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import accessKeyHelp from './access-key-help';

    export default {
        name: 'account-help',
        components: {
            accessKeyHelp,
        },
        data() {
            return {
                showHelp: false,
                tab: 0,
            };
        },
    };
</script>