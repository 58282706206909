<template>
    <app-navigation :heading="activeProject.name"
                    class="mb-4"
                    extend>
        <template #toolbar>
            <v-spacer></v-spacer>
            <view-selector :project-id="activeProject._id"></view-selector>
        </template>
        <template #extension>
            <v-tabs v-model="currentWorktray"
                    align-with-title
                    centered
                    background-color="primary">
                <v-tab v-for="worktray in worktrays"
                       :key="worktray._id">
                    <v-badge v-if="worktray.itemCount"
                             :content="worktray.itemCount"
                             inline
                             color="secondary">{{worktray.name}}</v-badge>
                    <span v-else>{{worktray.name}}</span>
                </v-tab>
            </v-tabs>
            <template v-if="activeWorktray.type === 'Assigning'">
                <v-spacer></v-spacer>
                <v-btn @click.stop="togglePageFilters()"
                       text>
                    <v-icon>fa-sliders-h</v-icon>
                </v-btn>
            </template>
        </template>
        <v-tabs-items v-model="currentWorktray" class="no-window-overflow mt-3">
            <v-tab-item v-for="worktray in worktrays"
                        :key="worktray._id"
                        class="full-screen"
                        :class="{'d-flex': activeWorktray._id === worktray._id && activeWorktray.type !== 'Assigning'}">
                <worktray-active v-if="worktray._id === activeWorktray._id">
                </worktray-active>
            </v-tab-item>
        </v-tabs-items>
    </app-navigation>
</template>
<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import beforeEnterGuard from '../router/beforeEnterGuard';

    import appNavigation from '@/components/app-navigation';
    import viewSelector from '@/modules/projects/components/view-selector';
    import worktrayActive from '@/modules/projects/components/worktray-active';

    export default {
        name: 'worktrays-board',
        components: {
            appNavigation,
            viewSelector,
            worktrayActive,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                currentWorktrayIndex: 0,
                showFilters: false,
                showWorktrays: true,
            };
        },
        computed: {
            currentWorktray: {
                get() {
                    return this.currentWorktrayIndex;
                },
                set(newIndex) {
                    this.currentWorktrayIndex = newIndex;

                    const worktrayId = this.activeProject.worktrays[newIndex];

                    if (
                        !this.activeWorktray ||
                        this.activeWorktray._id !== worktrayId
                    )
                        this.$store.dispatch(
                            'organisation/projects/activeProjectWorktrays/switch',
                            { worktrayId }
                        );
                },
            },
            worktrays() {
                return this.activeProject.worktrays.map(id =>
                    this.worktrayById(id)
                );
            },
            ...mapState('organisation/projects', ['activeProject']),
            ...mapState('organisation/projects/activeProjectWorktrays', [
                'activeWorktray',
            ]),
            ...mapState('organisation/projects/assigning', ['confirmAssignments']),
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'worktrayById',
            ]),
        },
        methods: {
            ...mapMutations(['togglePageFilters']),
        },
        beforeRouteUpdate (to, from, next) {
            return beforeEnterGuard(this.$store, to, from, next);
        },
        beforeMount() {
            if (this.activeWorktray) {
                const worktray = this.activeProject.worktrays.find(
                    id => id === this.activeWorktray._id
                );

                this.currentWorktray =
                    this.activeProject.worktrays.indexOf(worktray) || 0;
            }
        },
    };
</script>
<style scoped>
    .no-window-overflow {
        overflow: unset;
    }
</style>