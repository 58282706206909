<template>
    <access-key-confirm v-if="confirm"
                        :account-id="accountId"
                        :access-key="accessKey"
                        :submittable-org="submittableOrg"
                        @cancel="cancel"
                        @exit="$emit('exit')"></access-key-confirm>
    <access-key-enter v-else
                      v-model="accessKey"
                      :account-id="accountId"
                      :cant-exit="cantExit"
                      @cancel="cancel"
                      @found-organisation="foundOrg($event)"></access-key-enter>
</template>
<script>
    import { mapGetters } from 'vuex';

    import accessKeyConfirm from './access-key-confirm';
    import accessKeyEnter from './access-key-enter';

    export default {
        name: 'maintain-access-key',
        components: {
            accessKeyConfirm,
            accessKeyEnter,
        },
        props: {
            /** If an account ID is not specified, a new account will be set up */
            accountId: String,
        },
        data() {
            return {
                accessKey: null,
                confirm: false,
                submittableOrg: null,
            };
        },
        computed: {
            cantExit() {
                return this.accountsCount <= 0;
            },
            ...mapGetters('organisation/submittable', ['accountsCount']),
        },
        methods: {
            cancel() {
                // If we can't exit then lets just reload the page (vues way)
                if (this.cantExit) this.$router.go(0);
                else this.$emit('exit');
            },
            foundOrg(org) {
                this.submittableOrg = org;
                this.confirm = true;
            },
        },
    };
</script>