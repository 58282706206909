<template>
    <portal to="page-filters">
        <v-card outlined>
            <v-card-title class="pt-2">Submissions</v-card-title>
            <v-card-subtitle class="py-0">Include submissions that are:</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="includeUnassigned"
                              mandatory
                              multiple
                              hide-details
                              active-class="primary--text">
                    <v-chip>Unassigned</v-chip>
                    <v-chip>Assigned</v-chip>
                </v-chip-group>
            </v-card-text>
            <v-card-subtitle class="py-0">Include these categories:</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="categoriesToInclude"
                              multiple
                              mandatory
                              hide-details
                              column>
                    <category-chip v-for="catId in worktrayCategories"
                                   :key="catId"
                                   :no-color="categoriesToExclude[catId]"
                                   :category-id="catId"></category-chip>
                </v-chip-group>
            </v-card-text>
            <v-card-subtitle class="py-0">Sort submissions by:</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="submissionsSort"
                              mandatory
                              hide-details
                              column
                              active-class="primary--text">
                    <v-chip value="submitted">Submitted Date</v-chip>
                    <v-chip value="category">Category</v-chip>
                    <v-chip value="reason">Assigning Reason</v-chip>
                </v-chip-group>
                <v-chip-group v-model="submissionsSortAscending"
                              mandatory
                              hide-details
                              active-class="primary--text">
                    <v-chip>Ascending</v-chip>
                    <v-chip>Descending</v-chip>
                </v-chip-group>
            </v-card-text>
            <template v-if="!staffLockedHide">
                <v-card-subtitle class="py-0">Locked Staff</v-card-subtitle>
                <v-card-text>
                    <v-chip-group v-model="submissionsStaffHideLocked"
                                  mandatory
                                  hide-details
                                  active-class="primary--text">
                        <v-chip>Hide</v-chip>
                        <v-chip>Disable</v-chip>
                    </v-chip-group>
                </v-card-text>
            </template>
        </v-card>
        <v-card outlined>
            <v-card-title>Staff</v-card-title>
            <v-card-subtitle class="py-0">Sort staff by their:</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="staffSort"
                              column
                              mandatory
                              hide-details
                              active-class="primary--text">
                    <v-chip value="count">Assigned Count</v-chip>
                    <v-chip value="name">Name</v-chip>
                    <v-chip value="lastAssigned">Last Assigned</v-chip>
                </v-chip-group>
                <v-chip-group v-model="staffSortAscending"
                              mandatory
                              hide-details
                              active-class="primary--text">
                    <v-chip>Ascending</v-chip>
                    <v-chip>Descending</v-chip>
                </v-chip-group>
            </v-card-text>
            <v-card-subtitle class="py-0">Existing Submission Count</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="staffShowExistingSubs"
                              mandatory
                              hide-details
                              active-class="primary--text">
                    <v-chip>Show</v-chip>
                    <v-chip>Hide</v-chip>
                </v-chip-group>
            </v-card-text>
            <v-card-subtitle class="py-0">Locked Staff</v-card-subtitle>
            <v-card-text>
                <v-switch v-model="staffLockedHide"
                          label="Hide locked staff"
                          hide-details></v-switch>
                <v-switch v-if="!staffLockedHide"
                          v-model="staffLockedSortAtEnd"
                          label="Show at end of list"
                          hide-details></v-switch>
            </v-card-text>
        </v-card>
    </portal>
</template>
<script>
    import { mapGetters } from 'vuex';

    import categoryChip from '@/components/category-chip';

    export default {
        name: 'worktray-assign-least-subs-filters',
        components: {
            categoryChip,
        },
        computed: {
            includeUnassigned: {
                get() {
                    const inc = [];
                    if (this.display.includeUnassigned) inc.push(0);
                    if (this.display.includeAssigned) inc.push(1);
                    return inc;
                },
                set(newVal) {
                    this.update({
                        includeUnassigned: newVal.indexOf(0) > -1,
                        includeAssigned: newVal.indexOf(1) > -1,
                    });
                },
            },
            categoriesToExclude() {
                return this.display.categoriesToExclude;
            },
            categoriesToInclude: {
                get() {
                    return this.worktrayCategoriesToInclude;
                },
                set(newVal) {
                    const update = {
                        includeCats: newVal,
                        excludeCats: this.worktrayCategories.filter(
                            c => newVal.indexOf(c) === -1
                        ),
                    };
                    this.$store.commit(
                        'organisation/projects/assigning/updateCategoriesToExclude',
                        update
                    );
                },
            },
            staffShowExistingSubs: {
                get() {
                    return this.display.staffShowExistingSubs ? 0 : 1;
                },
                set(newVal) {
                    this.update({ staffShowExistingSubs: newVal === 0 });
                },
            },
            staffLockedHide: {
                get() {
                    return this.display.staffLockedHide;
                },
                set(newVal) {
                    this.update({ staffLockedHide: newVal });
                },
            },
            staffLockedSortAtEnd: {
                get() {
                    return this.display.staffLockedSortAtEnd;
                },
                set(newVal) {
                    this.update({ staffLockedSortAtEnd: newVal });
                },
            },
            staffSort: {
                get() {
                    return this.display.staffSort;
                },
                set(newVal) {
                    this.update({ staffSort: newVal });
                },
            },
            staffSortAscending: {
                get() {
                    return this.display.staffSortAscending ? 0 : 1;
                },
                set(newVal) {
                    this.update({ staffSortAscending: newVal === 0 });
                },
            },
            submissionsSort: {
                get() {
                    return this.display.submissionsSort;
                },
                set(newVal) {
                    this.update({ submissionsSort: newVal });
                },
            },
            submissionsSortAscending: {
                get() {
                    return this.display.submissionsSortAscending ? 0 : 1;
                },
                set(newVal) {
                    this.update({ submissionsSortAscending: newVal === 0 });
                },
            },
            submissionsStaffHideLocked: {
                get() {
                    return this.display.submissionsStaffHideLocked ? 0 : 1;
                },
                set(newVal) {
                    this.update({ submissionsStaffHideLocked: newVal === 0 });
                },
            },
            ...mapGetters('organisation/projects/assigning', [
                'worktrayCategories',
                'worktrayCategoriesToInclude',
                'display',
            ]),
        },
        methods: {
            update(newSettings) {
                this.$store.commit(
                    'organisation/projects/assigning/updateDisplaySettings',
                    newSettings
                );
            },
        },
    };
</script>