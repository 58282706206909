<template>
    <div :title="title">
        <v-chip :value="_id"
                :disabled="wasAlreadyAssigned || lockedForWorktray"
                label>
            {{staffer.firstName}} {{ staffer.surname}}
        </v-chip>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    import changeNounToPossessive from '@/helpers/changeNounToPossessive';

    export default {
        name: 'worktray-assign-staff-chip',
        props: {
            staffer: {
                type: Object,
                required: true,
            },
            submission: {
                type: Object,
                required: true,
            },
            submissionAssigneeId: String,
        },
        computed: {
            _id() {
                return this.staffer._id;
            },
            lockedForWorktray() {
                return !!this.staffLockedForActiveWorktray[this._id];
            },
            wasAlreadyAssigned() {
                return this.stafferWasAlreadyAssignedInThisWorktray(
                    this._id,
                    this.submission
                );
            },
            title() {
                const firstName = this.staffer.firstName;
                const title = this.submission.title;

                return this.lockedForWorktray
                    ? `As you've locked ${changeNounToPossessive(
                          firstName
                      )} submissions, you can no longer assign to ${firstName}. `
                    : this.wasAlreadyAssigned
                    ? `${firstName} has already been assigned '${title}'.`
                    : this.titleAssign;
            },
            titleAssign() {
                const title = this.submission.title;
                const firstName = this.staffer.firstName;
                return this.submissionAssigneeId === this._id
                    ? `Unassign '${title}' from ${firstName}`
                    : `Assign '${title}' to ${firstName}`;
            },
            ...mapGetters('organisation/projects/assigning', [
                'staffLockedForActiveWorktray',
                'stafferWasAlreadyAssignedInThisWorktray',
            ]),
        },
    };
</script>