<template>
    <app-navigation heading="Submittable Accounts">
        <v-container>
            <access-key-maintain v-if="enterAccessKey"
                                 :account-id="accountIdForAccessKey"
                                 @exit="exitEditAccessKey">
            </access-key-maintain>
            <v-simple-table v-else
                            class="mt-3">
                <thead>
                    <tr>
                        <th scope="col">Account Name</th>
                        <th scope="col">Status</th>
                        <th scope="col"
                            class="min-width"></th>
                    </tr>
                </thead>
                <tbody>
                    <account-row v-for="account in accountsArray"
                                 :key="account._id"
                                 :account-id="account._id"
                                 @change-access-key="changeAccessKey">
                    </account-row>
                    <tr>
                        <td colspan="4"
                            class="text-center">
                            <v-btn color="green"
                                   text
                                   block
                                   @click="enterAccessKey = true">
                                <v-icon>fa-plus</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-container>
        <error-dialog></error-dialog>
        <account-help></account-help>
    </app-navigation>
</template>
<script>
    import { mapGetters } from 'vuex';

    import accessKeyMaintain from '@/modules/submittable/components/access-key-maintain';
    import errorDialog from '@/modules/submittable/components/account-error-dialog';
    import accountHelp from '@/modules/submittable/components/account-help';
    import accountRow from '@/modules/submittable/components/account-row';
    import appNavigation from '@/components/app-navigation';

    export default {
        name: 'accounts',
        components: {
            accessKeyMaintain,
            errorDialog,
            accountHelp,
            accountRow,
            appNavigation,
        },
        data() {
            return {
                enterAccessKey: false,
                accountIdForAccessKey: null,
            };
        },
        computed: {
            newAccessKey() {
                return this.enterAccessKey && !this.accountIdForAccessKey;
            },
            ...mapGetters('organisation/submittable', [
                'accountsCount',
                'accountsArray',
            ]),
        },
        methods: {
            changeAccessKey(accountId) {
                this.enterAccessKey = true;
                this.accountIdForAccessKey = accountId;
            },
            exitEditAccessKey() {
                this.enterAccessKey = false;
                this.accountIdForAccessKey = null;
            },
        },
        created() {
            // If we don't have any accounts, then go into enter access key mode
            this.enterAccessKey = this.accountsCount === 0;
        },
    };
</script>