<template>
    <v-container class="py-0 px-2 px-md-4 px-lg-8"
                 fluid>
        <v-row>
            <v-col cols="6"
                   class="scrollable-column pr-7 no-overflow-extended-menu">
                <h3 class="text-center mb-2">Submissions to Assign <span v-if="submissionList.length"> ({{submissionList.length}})</span></h3>
                <div v-if="!submissionList.length"
                     class="no-submissions-msg">
                    <template v-if="submissionsToAssignCount">
                        <p>There are no submissions left to display. However, you still have <b>{{submissionsToAssignCount}}</b> submission{{submissionsToAssignCount === 1 ? '' : 's'}} to assign.</p>
                        <p>You are currently <b>displaying {{includeAssignedMessage}}</b> submissions in these categories:</p>
                        <div class="d-inline-block">
                            <category-chip v-for="catId in worktrayCategoriesToInclude"
                                           :key="catId"
                                           :category-id="catId"
                                           class="mx-1"></category-chip>
                        </div>
                        <b v-if="!worktrayCategoriesToInclude.length"><em>All categories are excluded</em></b>
                    </template>
                    <div v-else
                         class="mt-10">
                        <em>All submissions have been assigned!</em>
                    </div>
                </div>
                <submission-card v-for="sub in submissionList"
                                 :key="sub._id"
                                 :submission-id="sub._id"
                                 class="mb-2"></submission-card>

            </v-col>
            <div class="half-page-container"></div>
            <v-col cols="6"
                   class="pl-7">
                <h3 class="text-center mb-2">Available Staff ({{staffOrderedForNextSubmission.length}}<span v-if="lockedCount">: {{staffOrderedForNextSubmission.length - lockedCount}} Assignable, {{lockedCount}} Locked</span>)</h3>
                <staff-card v-for="staffer in staffOrderedForNextSubmission"
                            :key="staffer._id"
                            :staffer="staffer"
                            class="mb-2">
                </staff-card>
            </v-col>
        </v-row>
        <v-speed-dial v-if="hasAssignments || worktrayHasDraft"
                      v-model="fab"
                      fixed
                      bottom
                      right
                      open-on-hover
                      transition="scale-transition">
            <template #activator>
                <v-btn v-model="fab"
                       color="blue darken-2"
                       dark
                       large
                       fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-dots-horizontal</v-icon>
                </v-btn>
            </template>
            <v-btn v-if="hasAssignments"
                   fab
                   title="Assign submissions"
                   dark
                   color="primary"
                   @click="confirmAssignments">
                <v-icon>mdi-send</v-icon>
            </v-btn>
            <v-btn v-if="hasAssignments"
                   fab
                   title="Save a draft of the current assignments"
                   dark
                   color="green"
                   @click="draftSave">
                <v-icon>cloud_upload</v-icon>
            </v-btn>
            <v-btn v-if="worktrayHasDraft"
                   fab
                   dark
                   title="Load a previously saved draft"
                   color="grey"
                   @click="draftLoad">
                <v-icon>cloud_download</v-icon>
            </v-btn>
        </v-speed-dial>
        <least-subs-filters></least-subs-filters>
    </v-container>
</template>
<script>
    import _ from 'lodash';
    import { mapGetters, mapState } from 'vuex';

    import categoryChip from '@/components/category-chip';
    import leastSubsFilters from './worktray-assign-least-subs-filters';
    import submissionCard from './worktray-assign-least-subs-card';
    import staffCard from './worktray-assign-least-subs-staffer';

    export default {
        name: 'worktray-assign-least-subs',
        components: {
            categoryChip,
            leastSubsFilters,
            submissionCard,
            staffCard,
        },
        props: {
            assignMethod: String,
        },
        data: () => ({
            fab: false,
        }),
        computed: {
            includeAssignedMessage() {
                return this.incAssigned && this.incUnassigned
                    ? 'assigned and unassigned'
                    : this.incAssigned
                    ? 'assigned'
                    : this.incUnassigned
                    ? 'unassigned'
                    : 'NEITHER assinged NOR unassigned';
            },
            lockedCount() {
                return Object.values(this.staffLockedForActiveWorktray).filter(
                    isLocked => isLocked
                ).length;
            },
            ...mapState('organisation/projects/assigning', {
                hasAssignments: state => !_.isEmpty(state.assigned),
                incAssigned: state => state.display.includeAssigned,
                incUnassigned: state => state.display.includeUnassigned,
            }),
            ...mapGetters('organisation/projects/assigning', [
                'staffOrderedForNextSubmission',
                'staffLockedForActiveWorktray',
                'submissionList',
                'submissionsToAssignCount',
                'worktrayCategoriesToInclude',
                'worktrayHasDraft',
            ]),
        },
        methods: {
            draftSave() {
                this.$store.dispatch('organisation/projects/assigning/draftSave');
            },
            draftLoad() {
                this.$store.dispatch(
                    'organisation/projects/assigning/draftLoadFromStorage'
                );
            },
            confirmAssignments() {
                this.$store.commit(
                    'organisation/projects/assigning/setConfirmAssignments',
                    true
                );
            },
        },
        created() {
            this.$store.dispatch(
                'organisation/projects/assigning/draftCheckExists'
            );
        },
    };
</script>
<style scoped>
    .no-submissions-msg {
        height: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .scrollable-column {
        overflow-y: overlay;
        overflow-x: hidden;
        max-height: calc(100vh - 112px);
        position: sticky;
        top: 112px;
    }

    .half-page-container {
        height: 100vh;
        position: fixed;
        left: calc(50vw - 8px);
        top: 0;
        margin-right: -1px;
        border-right: 1px solid #f1f1f1;
    }
</style>