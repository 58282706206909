<template>
    <portal to="page-filters">
        <v-btn-toggle v-model="assignMethodBound"
                      tile
                      group
                      mandatory>
            <v-btn value="simple">
                Simple
            </v-btn>
            <v-btn value="leastSubs">
                Least Subs
            </v-btn>
        </v-btn-toggle>
    </portal>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        computed: {
            assignMethodBound: {
                get() {
                    return this.method;
                },
                set(newMethod) {
                    this.$store.commit(
                        'organisation/projects/assigning/setAssigningMethod',
                        {
                            method: newMethod,
                        }
                    );
                    this.showBound = false;
                },
            },
            ...mapState('organisation/projects/assigning', ['method']),
        },
    };
</script>