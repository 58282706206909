<template>
    <v-container class="mh-100"
                 :class="{'d-flex align-center justify-center': !activeWorktrayPage.length }">
        <template v-if="activeWorktrayPage.length">
            <v-row justify="center">
                <slot name="submissions"
                      :submissions-page="activeWorktrayPage"></slot>
            </v-row>
            <div v-if="activeWorktrayPage.length < activeWorktrayTotalItems"
                 class="d-flex">
                <v-btn class="mx-auto"
                       @click="activeWorktrayChangePageSize({ loadMore: true })">Load more</v-btn>
            </div>
        </template>
        <div v-else
             class="d-flex align-center justify-center">
            <div>
                <h2 class="text-center">Nothing to see here!</h2>
                <em>Well done, you must be on top of things!</em>
            </div>
        </div>
    </v-container>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'worktray-pager',
        computed: {
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'activeWorktrayPage',
                'activeWorktrayTotalItems',
            ]),
        },
        methods: {
            ...mapMutations('organisation/projects/activeProjectWorktrays', [
                'activeWorktrayChangePageSize',
            ]),
        },
    };
</script>