<template>
    <tr>
        <td>
            <v-text-field v-model.trim="nameBound"
                          solo
                          :hide-details="!nameValidationMessage"
                          :error-messages="nameValidationMessage"
                          class="my-2">
            </v-text-field>
        </td>
        <td>
            <template v-if="account.failedConnection">
                <v-icon color="red"
                        class="ml-3 mr-2">fa-exclamation-triangle</v-icon>
                <span>Access key has been rejected, please update it</span>
            </template>
            <v-switch v-else
                      v-model="statusActive"
                      hide-details
                      class="mx-2 mt-0"
                      label="active"></v-switch>
        </td>
        <td>
            <div class="d-flex justify-end">
                <v-btn @click="changeAccessKey">
                    Change Access Key
                </v-btn>
                <v-btn icon
                       class="ml-2"
                       @click="confirmDelete = true">
                    <v-icon>fa-trash</v-icon>
                    <v-dialog v-model="confirmDelete"
                              max-width="30%">
                        <v-card>
                            <v-card-title>Delete {{name || 'unamed account'}}?</v-card-title>
                            <v-card-text>Are you sure you want to delete the {{name ? 'account ' + name : 'unamed account'}}?</v-card-text>
                            <v-card-text>Deleting an account will mean that it is not possible to import submissions from it, but will not delete any data from any imported submissions.</v-card-text>
                            <card-actions>
                                <v-btn text
                                       color="red"
                                       @click="deleteAccount">Yes</v-btn>
                                <v-btn text
                                       @click="confirmDelete = false">No</v-btn>
                            </card-actions>
                        </v-card>
                    </v-dialog>
                </v-btn>
            </div>
        </td>
    </tr>
</template>
<script>
    import { mapGetters } from 'vuex';

    export const fields = {
        nameValid: false,
    };

    export default {
        name: 'account-row',
        props: {
            accountId: String,
        },
        data() {
            return {
                confirmDelete: false,
                name: '',
                nameChangeTimeout: null,
                nameDuplicate: null,
            };
        },
        computed: {
            account() {
                return this.accounts.find(a => a._id === this.accountId);
            },
            nameBound: {
                get() {
                    return this.name;
                },
                set(name) {
                    this.name = name;
                    clearTimeout(this.nameChangeTimeout);
                    if (name)
                        this.nameChangeTimeout = setTimeout(() => {
                            this.$store
                                .dispatch(
                                    'organisation/submittable/updateAccount',
                                    {
                                        accountId: this.accountId,
                                        name,
                                    }
                                )
                                .catch(err => {
                                    const resp = err.response;
                                    if (
                                        resp &&
                                        resp.status === 409 &&
                                        resp.data.isDuplicate
                                    ) {
                                        // Duplicate on name
                                        this.nameDuplicate = resp.data.name
                                            ? name
                                            : null;

                                        // Handled situation:
                                        if (resp.data.name) return;
                                    }

                                    return this.$handleError(err);
                                });
                        }, 2000);
                },
            },
            nameIsDuplicate() {
                return (
                    this.name === this.nameDuplicate ||
                    this.accounts.find(
                        acc => acc.name === this.name && acc._id !== this.accountId
                    )
                );
            },
            nameValidationMessage() {
                return !this.name
                    ? 'A name is required'
                    : this.nameIsDuplicate
                    ? 'Duplicate names are not allowed'
                    : null;
            },
            statusActive: {
                get() {
                    return this.account.active;
                },
                set(active) {
                    this.$store
                        .dispatch('organisation/submittable/updateAccount', {
                            accountId: this.accountId,
                            active,
                        })
                        .catch(err => this.$handleError(err));
                },
            },
            ...mapGetters('organisation/submittable', {
                accounts: 'accountsArray',
            }),
        },
        methods: {
            changeAccessKey() {
                this.$emit('change-access-key', this.accountId);
            },
            deleteAccount() {
                this.$store
                    .dispatch('organisation/submittable/deleteAccount', {
                        accountId: this.accountId,
                    })
                    .catch(err => this.$handleError(err));
                this.confirmDelete = false;
            },
        },
        created() {
            this.name = this.account.name;
        },
    };
</script>