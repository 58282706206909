<template>
    <v-card>
        <div class="stick-to-header">
            <slot name="title">
                <v-card-title class="justify-space-between flex-nowrap">
                    <div :title="submission.title"
                         class="text-truncate pr-2">{{submission.title}}</div>
                    <submission-indicators :submission="submission">
                        <template #pre-category>
                            <slot name="submission-indicators-pre-category"></slot>
                        </template>
                    </submission-indicators>
                </v-card-title>
            </slot>
        </div>
        <submitter-details v-if="showSubmitter"
                           :submitter="submission.submitter"></submitter-details>
        <template v-if="showPreviousSteps">
            <v-card-subtitle class="pb-0">Steps</v-card-subtitle>
            <previous-steps-details :previous-steps="submission.steps"
                                    :submitter="submission.submitter"
                                    class="mx-2"></previous-steps-details>
        </template>
        <template v-if="showFiles">
            <v-card-subtitle class="pb-0">Files</v-card-subtitle>
            <file-list :files="submission.files"
                       @file-selected="downloadFile"
                       class="mx-4"></file-list>
        </template>
        <template v-if="showSource">
            <v-card-subtitle class="pb-0">Source</v-card-subtitle>
            <submission-source-status :source="submission.source"></submission-source-status>
        </template>
        <card-actions>
            <slot name="actions"></slot>
            <template #secondary>
                <slot name="secondary"></slot>
            </template>
        </card-actions>
    </v-card>
</template>
<script>
    import fileList from '@/components/file-list';
    import submissionIndicators from '@/components/submission-indicators';
    import submissionPreviousStepsDetails from '@/components/submission-previous-steps-details';
    import submissionSourceStatus from '@/components/submission-source-status';
    import submitterDetails from '@/components/submitter-details';

    export default {
        name: 'submission-details',
        components: {
            fileList,
            previousStepsDetails: submissionPreviousStepsDetails,
            submissionIndicators,
            submissionSourceStatus,
            submitterDetails,
        },
        props: {
            submission: {
                type: Object,
                required: true,
            },
            showFiles: Boolean,
            showPreviousSteps: Boolean,
            showSource: Boolean,
            showSubmitter: Boolean,
        },
        methods: {
            downloadFile(fileId) {
                this.$emit('download-file', fileId);
            },
        },
    };
</script>
<style scoped>
    .stick-to-header {
        background-color: white;
        z-index: 2;
        box-shadow: 0px 6px 2px -1px rgba(255, 255, 255, 0.37);
    }
</style>