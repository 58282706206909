<template>
    <v-simple-table>
        <thead>
            <tr>
                <th scope="col">Original File Name</th>
                <th scope="col"
                    class="w-50">Working File Name</th>
                <th scope="col"
                    class="min-width">Defaults</th>
                <th scope="col"
                    class="min-width"></th>
            </tr>
        </thead>
        <tbody>
            <file-row v-for="file in submission.files"
                      :key="`${file._id}_${file.version}`"
                      :file="file"
                      :submission-id="submissionId"
                      :disabled="disabled"></file-row>
        </tbody>
    </v-simple-table>
</template>
<script>
    import { mapGetters } from 'vuex';

    import fileRow from './worktray-cleaning-file-row';

    export default {
        name: 'worktray-cleaning-files',
        components: {
            fileRow,
        },
        props: {
            submissionId: {
                type: String,
                required: true,
            },
            disabled: Boolean,
        },
        computed: {
            submission() {
                return this.submissionById(this.submissionId);
            },
            ...mapGetters('organisation/projects/activeProjectWorktrays', [
                'submissionById',
            ]),
        },
    };
</script>