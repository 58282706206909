<template>
    <v-card-text>
        <v-list subheader>
            <v-list-item :href="source.link"
                         target="_none">
                <v-list-item-content>
                    <v-list-item-title>{{source.type}}</v-list-item-title>
                    <v-list-item-subtitle>Last sync: <em>{{$displayDate(source.lastSync, true)}}</em></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-btn :value="showForm"
                           text
                           fab
                           small
                           class="mr-2"
                           :title="showFormTitle"
                           @click.prevent="toggleShowForm">
                        <v-icon>fa-list-alt</v-icon>
                    </v-btn>
                    <v-icon v-if="source.isArchived"
                            class="mr-2"
                            title="Archived">fa-archive</v-icon>
                    <status-indicator :status="source.status"
                                      small
                                      title="The status in Submittable when this submission was last seen"
                                      class="align-self-center"></status-indicator>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-card-text v-if="showForm"
                     class="pt-0">
            <v-divider class="mb-4"></v-divider>
            <submittable-fields :fields="source.form.items"></submittable-fields>
            <v-divider></v-divider>
        </v-card-text>
    </v-card-text>
</template>
<script>
    import statusIndicator from '@/components/status-indicator';
    import submittableFields from '@/components/submittable-fields';

    export default {
        name: 'submission-source-status',
        components: {
            statusIndicator,
            submittableFields,
        },
        props: {
            source: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            showForm: false,
        }),
        computed: {
            showFormTitle() {
                return this.showForm ? 'Hide form' : 'Show form';
            },
        },
        methods: {
            toggleShowForm() {
                this.showForm = !this.showForm;
            },
        },
    };
</script>