<template>
    <v-dialog :value="showDialog"
              max-width="30%">
        <v-card>
            <template v-if="missingAccount">
                <v-card-title>Missing Submittable Account</v-card-title>
                <v-card-text>The record we had for your Submittable account you were trying to access has gone missing. Most likely, this is the result of someone else deleting the account.</v-card-text>
                <v-card-text>Please review your accounts below.</v-card-text>
            </template>
            <template v-else-if="noAccounts">
                <v-card-title>Configure Submittable Accounts</v-card-title>
                <v-card-text>You must configure at least one submittable account before you can manage your submissions.</v-card-text>
                <v-card-text>Further help can be accessed at the bottom right of the screen</v-card-text>
            </template>
            <template v-else-if="rejectedKey">
                <v-card-title>Access Key was Rejected</v-card-title>
                <v-card-text>Submittable rejected the access key we were using. Please review your accounts.</v-card-text>
            </template>
            <card-actions>
                <v-btn color="primary"
                       @click="showDialog = false">OK</v-btn>
            </card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'account-error-dialog',
        data() {
            return {
                showDialog: false,
            };
        },
        computed: {
            missingAccount() {
                return this.$route.params.missingAccount;
            },
            noAccounts() {
                return this.$route.params.noAccounts;
            },
            rejectedKey() {
                return this.$route.params.rejectedKey;
            },
        },
        created() {
            this.showDialog =
                this.missingAccount || this.noAccounts || this.rejectedKey;
        },
    };
</script>