<template>
    <v-container class="full-screen__only">
        <v-card class="card__full-size">
            <v-card-title>Confirm Assignments</v-card-title>
            <v-card-text class="overflow-y-auto flex-grow-1">
                <table :class="{ loading: loading || complete }">
                    <staff-details v-for="s in staff"
                                   :key="s._id"
                                   :staffer="s"
                                   :assigned="complete">
                    </staff-details>
                </table>
            </v-card-text>
            <card-actions v-if="complete">
                <v-btn text
                       color="primary"
                       @click="back"
                       :loading="loading">Done</v-btn>
            </card-actions>
            <card-actions v-else>
                <v-btn text
                       color="primary"
                       @click="assignAll"
                       :loading="loading">Assign Submissions</v-btn>
                <template #secondary>
                    <v-btn text
                           :disabled="loading"
                           @click="back">Back</v-btn>
                </template>
            </card-actions>
        </v-card>
        <portal to="page-filters">
            <v-card outlined>
                <v-card-title>Submissions</v-card-title>
                <v-card-subtitle class="py-0">Existing Submission Count</v-card-subtitle>
                <v-card-text>
                    <v-chip-group v-model="staffShowExistingSubs"
                                  mandatory
                                  hide-details
                                  active-class="primary--text">
                        <v-chip>Show</v-chip>
                        <v-chip>Hide</v-chip>
                    </v-chip-group>
                </v-card-text>
            </v-card>
        </portal>
    </v-container>
</template>
<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';

    import staffDetails from './worktray-assign-confirm-staffer';

    export default {
        name: 'worktray-assign-confirm',
        components: {
            staffDetails,
        },
        data: () => ({
            loading: false,
            complete: false,
            staff: undefined,
        }),
        computed: {
            staffShowExistingSubs: {
                get() {
                    return this.display.staffShowExistingSubs ? 0 : 1;
                },
                set(newVal) {
                    this.updateDisplaySettings({
                        staffShowExistingSubs: newVal === 0,
                    });
                    this.staff = this.staffOrderByName(true, false, true);
                },
            },
            ...mapState('organisation/projects/assigning', ['confirmAssignments']),
            ...mapGetters('organisation/projects/assigning', [
                'display',
                'staffOrderByName',
            ]),
        },
        methods: {
            assignAll() {
                this.loading = true;
                this.$store
                    .dispatch('organisation/projects/assigning/process')
                    .then(() => {
                        this.loading = false;
                        this.complete = true;
                    });
            },
            back() {
                this.$store.commit(
                    'organisation/projects/assigning/setConfirmAssignments',
                    false
                );
            },
            ...mapMutations('organisation/projects/assigning', [
                'updateDisplaySettings',
            ]),
        },
        created() {
            this.staff = this.staffOrderByName(true, false, true);
        },
        destroyed() {
            // If the component is destroyed after completing then we should not enter the confirm next time
            if (this.complete && this.confirmAssignments)
                this.$store.commit(
                    'organisation/projects/assigning/setConfirmAssignments',
                    false
                );
        },
    };
</script>
<style scoped>
    table {
        width: 100%;
    }

    .card__full-size {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .overflow-y-auto {
        overflow-y: auto;
    }
</style>