<template>
    <worktray-pager>
        <template #submissions="{submissionsPage}">
            <multiple-choice v-for="sub in submissionsPage"
                             :key="sub._id"
                             :submission-id="sub._id"
                             :reason-id="sub.reasonId"></multiple-choice>
        </template>
    </worktray-pager>
</template>
<script>
    import worktrayPager from './worktray-pager';
    import worktrayMultipleChoiceCard from './worktray-multiple-choice-card';

    export default {
        name: 'worktray-multiple-choice',
        components: {
            worktrayPager,
            'multiple-choice': worktrayMultipleChoiceCard,
        },
    };
</script>