<template>
    <v-card v-if="showHelp">
        <v-card-title>Access Key Help</v-card-title>
        <access-key-help></access-key-help>
        <card-actions>
            <v-btn key="exit-help"
                   text
                   @click="showHelp = false">Back</v-btn>
        </card-actions>

    </v-card>
    <v-card v-else>
        <v-card-title>{{title}}</v-card-title>
        <v-card-text>
            <p class="mb-0">You can {{ newAccessKey ? 'enter' : 'update' }} the Access Key for {{accountPlaceholder}} by pasting it into the below text box. If you aren't sure where to find the Access Key, you can access instructions by clicking 'Help'.</p>
            <v-text-field v-model="accessKey"
                          :disabled="loading"
                          v-focus
                          label="Access Key"
                          class="pt-2 my-4"
                          :hide-details="accessKeyValid"
                          :error-messages="accessKeyValidMessage"
                          counter="32"
                          maxlength="32">
            </v-text-field>
            <p class="mb-0">Once you are happy that the Access Key is correct, hit 'Validate' and we will check that everythings OK so that we can use it to access Submittable.</p>
        </v-card-text>
        <card-actions>
            <v-btn text
                   key="validate"
                   color="primary"
                   :disabled="!accessKeyValid"
                   :loading="loading"
                   @click="validate">Validate</v-btn>
            <template #secondary>
                <v-btn v-if="!cantExit"
                       key="cancel-enter"
                       text
                       @click="$emit('cancel')">Cancel</v-btn>
                <v-btn text
                       key="show-help"
                       color="teal"
                       @click="showHelp = true">Help</v-btn>
            </template>
        </card-actions>
    </v-card>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';

    import accessKeyHelp from './access-key-help';

    export default {
        name: 'access-key-enter',
        components: {
            accessKeyHelp,
        },
        props: {
            accountId: String,
            cantExit: Boolean,
            value: String,
        },
        data() {
            return {
                failedAccessKey: null,
                isDuplicate: false,
                isOtherOrganisation: false,
                loading: false,
                showHelp: false,
            };
        },
        computed: {
            accountPlaceholder() {
                return this.newAccessKey
                    ? 'your new Submittable Account'
                    : 'the account ' + this.account.name;
            },
            accessKey: {
                get() {
                    return this.value;
                },
                set(newAccessKey) {
                    this.$emit('input', newAccessKey);
                },
            },
            accessKeyFailed() {
                return (
                    this.failedAccessKey === this.accessKey &&
                    this.failedAccessKey !== null
                );
            },
            accessKeyValid() {
                return this.accessKeyValidMessage === null;
            },
            accessKeyValidFormat() {
                return (
                    this.accessKey === null ||
                    this.accessKeyRegex.test(this.accessKey)
                );
            },
            accessKeyValidMessage() {
                return this.accessKeyFailed
                    ? this.isDuplicate
                        ? 'This Access Key is already in use. Duplicates are not allowed.'
                        : this.isOtherOrganisation
                        ? `This key is not for the Submittable Team "${this.account.name}". If you want to switch the team account, delete the existing account and then create a new one.`
                        : "We couldn't connect to Submittable with this Access Key. Please check that it's correct."
                    : this.accessKey !== ''
                    ? !this.accessKeyValidFormat
                        ? 'This Access Key is not in the expected format. Please double check it.'
                        : null
                    : 'You must enter an Access Key.';
            },
            validateUrl() {
                return this.prependUrl(
                    this.newAccessKey
                        ? '/submittable/accounts/testAccessKey'
                        : `/submittable/accounts/${this.accountId}/testNewAccessKey`
                );
            },
            newAccessKey() {
                return !this.accountId;
            },
            title() {
                return (
                    (this.newAccessKey
                        ? 'New Submittable Account: '
                        : `${this.account.name}: Change `) + 'Access Key'
                );
            },
            ...mapState('organisation/submittable', ['accessKeyRegex']),
            ...mapGetters('organisation/submittable', ['prependUrl']),
            ...mapGetters('organisation/submittable', {
                account(state) {
                    return this.accountId ? state.accounts[this.accountId] : null;
                },
            }),
        },
        methods: {
            validate() {
                // set it to empty value
                let key = (this.accessKey = this.accessKey || '');

                if (this.accessKeyValid) {
                    this.loading = true;
                    this.$http
                        .post(this.validateUrl, { accessKey: key })
                        .then(org => {
                            this.isDuplicate = false;
                            this.isOtherOrganisations = false;
                            this.$emit('found-organisation', org.data);
                            this.loading = false;
                        })
                        .catch(err => {
                            const res = err.response;
                            const data = res.data;
                            if (
                                res.status === 422 ||
                                res.status === 409 ||
                                res.status === 401
                            ) {
                                this.isDuplicate = !!data.isDuplicate;
                                this.isOtherOrganisation = !!data.isOtherOrganisation;
                                if (
                                    data.rejectedKey ||
                                    data.isDuplicate ||
                                    data.isOtherOrganisation
                                )
                                    this.failedAccessKey = this.accessKey;
                            } else this.$handleError(err);

                            this.loading = false;
                        });
                }
            },
        },
    };
</script>