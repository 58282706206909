<template>
    <v-card>
        <v-card-title class="justify-space-between flex-nowrap">
            <div :title="submission.title"
                 class="text-truncate pr-2">
                {{submission.title}}
            </div>
            <submission-indicators :submission="submission"
                                   editable-category>
            </submission-indicators>
        </v-card-title>
        <v-card-subtitle class="d-flex justify-space-between flex-nowrap pt-1">
            <div class="text-truncate mr-2"
                 :title="`${submitter.firstName} ${submitter.surname}`">
                <em>by</em> {{submitter.firstName}} {{submitter.surname}}
            </div>
            <div>
                <span class="d-none d-md-inline mr-1">Submitted:</span>
                <span>{{createdDate}}</span>
            </div>
        </v-card-subtitle>
        <v-card-actions>
            <v-select v-model="staffMember"
                      :items="staff"
                      hide-details
                      :item-text="s => `${s.firstName} ${s.surname}`"
                      item-value="_id"
                      label="Assign to"
                      class="w-50 d-inline-flex"></v-select>
            <v-spacer></v-spacer>
            <v-btn :disabled="!staffMember"
                   @click="assignSubmission"
                   text
                   :loading="loading">Assign</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex';

    import submissionIndicators from '@/components/submission-indicators';

    export default {
        name: 'worktray-assign-card',
        components: {
            submissionIndicators,
        },
        props: {
            submissionId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                staffMember: null,
                loading: false,
                submission: null,
            };
        },
        computed: {
            createdDateString() {
                const sub = this.submission;

                return !sub ? undefined : sub.createdDate;
            },
            createdDate() {
                return this.createdDateString
                    ? this.$displayDate(this.createdDateString)
                    : undefined;
            },
            submitter() {
                return this.submission.submitter;
            },
            ...mapGetters('organisation/projects', {
                staff: 'assigning/staffAssignableForActiveWorktray',
                submissionById: 'activeProjectWorktrays/submissionById',
            }),
        },
        methods: {
            assignSubmission() {
                if (!this.staffMember) return;

                // TODO: where is the error handling?
                this.loading = true;
                this.$store
                    .dispatch(
                        'organisation/projects/activeProjectWorktrays/assignSubmission',
                        {
                            assigneeId: this.staffMember,
                            submissionId: this.submission._id,
                        }
                    )
                    .then(() => (this.loading = false));
            },
        },
        created() {
            const sub = this.submissionById(this.submissionId);

            if (sub) this.submission = sub;
            else
                this.$store
                    .dispatch(
                        'organisation/projects/activeProjectWorktrays/submissionFetch',
                        {
                            submissionId: this.submissionId,
                        }
                    )
                    .then(sub => {
                        this.submission = sub;
                    });
        },
    };
</script>