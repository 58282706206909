<template>
    <assign-submission v-if="type === 'Assigning'"></assign-submission>
    <clean-submission v-else-if="type === 'Cleaning'"></clean-submission>
    <multiple-choice v-else-if="type === 'Multiple Choice'"></multiple-choice>
    <single-action v-else-if="type === 'Single Action'"></single-action>
    <v-card v-else>
        <v-card-title>Unknown</v-card-title>
    </v-card>
</template>
<script>
    import { mapState } from 'vuex';

    import worktrayAssign from './worktray-assign';
    import worktrayCleaning from './worktray-cleaning';
    import worktrayMultipleChoice from './worktray-multiple-choice';
    import worktraySingleAction from './worktray-single-action';

    export default {
        name: 'worktray-active',
        components: {
            'assign-submission': worktrayAssign,
            'clean-submission': worktrayCleaning,
            'multiple-choice': worktrayMultipleChoice,
            'single-action': worktraySingleAction,
        },
        computed: {
            type() {
                return this.activeWorktray.type;
            },
            ...mapState('organisation/projects/activeProjectWorktrays', [
                'activeWorktray',
            ]),
        },
    };
</script>